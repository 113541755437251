import React,
{
  useContext,
  useState,
  useReducer,
  useEffect
  

} from 'react'
import {
    Stack,
    Typography,
    Box
} from '@mui/material'
import { Button} from '@mui/material';
import { AuthContext } from '../../../../context/auth/AuthProvider';
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CreateJugador from './CreateJugador';
import EditJugador from './EditJugador';


function JugadoresScouting() {
  const {user, token} = useContext(AuthContext);

  const [jugadores,setJugadores] = useState(null);
  const [jugadorSelected, setJugadorSelected] = useState(null);
  const [reducerValue, forceUpdate] = useReducer(x=> x+1,0);

  // use effect for fetching players
  useEffect(() => {
      const fetchJugadores = async ()=>{
        const requestsOptions = {
          method: "GET",
          headers:{
            Authorization:"Bearer "+token
          }
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/jugadoresscouting`, requestsOptions)
        if(response.ok){
          const data = await response.json();
          setJugadores(data);
        }
      }
  
      fetchJugadores();
     
    },[token, reducerValue])
  
  
    // handle Click on Row to get user id
  const handleClickOnRow = (params)=>{
    setJugadorSelected(params.row);
    }

  //handle click eliminate
  const handleClickEliminate= ()=>{
      if(jugadorSelected){
          setAnchorEliminateJugador(true)
      }else{
          alert("Elige un jugador")
      }
  }
  // handleEliminate user
  const [anchorEliminateJugador, setAnchorEliminateJugador] = useState(false);
  const handleEliminateJugador = ()=>{
      if(jugadorSelected){
          const eliminateJugador = async()=>{
              const requestOptions = {
                method: "DELETE",
                headers:{
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token
                }
              }
              const response = await fetch(`${process.env.REACT_APP_API_URL}/jugadoresscouting/${jugadorSelected.id}`, requestOptions)
              if(response.ok){
                console.log("Jugador eliminated")
                setAnchorEliminateJugador(false);
                forceUpdate();
        
              }else{
                console.log("Hubo un problema al eliminar un jugador")
              }
        
            };
            eliminateJugador();
      }
  }

  // handle click create user
  const [anchorCreateJugador, setAnchorCreateJugador] = useState(false);
  const handleClickCreate = ()=>{
      setAnchorCreateJugador(true);
  }

  // handle Click edit user
  const [anchorEditJugador, setAnchorEditJugador] = useState(false);
  const handleClickEditJugador = ()=>{
      if(jugadorSelected){
          setAnchorEditJugador(true);
      }else{
          alert("Elige un jugador")
      }
  }



  const handleClose= ()=>{
      setAnchorEliminateJugador(false);
      setAnchorCreateJugador(false);
      setAnchorEditJugador(false)
  }
  return (
    <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
        marginY:{xs:1, sm:1, md:1,lg:10,xl:10},
        overflow:"hidden",
        maxWidth:"100%"}}>
      <Box width={300} sx={{backGround:"black" ,display:{xs:"none",sm:"none", md:"block"},}}></Box>
      
      <Box 
        sx={{
            width:"100%",
            marginX:{md:0,xl:2,lg:2},
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center"

        }}
      >
    <Typography variant="h3" sx={{textAlign:"center", }}>Jugadores Vistos</Typography>
      <Box
      sx={{
          marginTop:1,
          width:{xs:"95%",sm:"95%",md:"90%",lg:"90%"},
          marginX:"auto"
      }}>

          {
              jugadores && 
              <Box sx={{ width: '100%', marginTop:0 }}>
              <Stack spacing={2} direction="row" justifyContent={"space-around"} sx={{marginY:5}}>
                  {/* <Button onClick={handleClickCreate} color="success" variant="outlined">Agregar jugador</Button>
                  <Button onClick={handleClickEditJugador} color="blueSecondary"  variant="outlined">Editar jugador</Button>
                  <Button onClick={handleClickEliminate}  variant="outlined">Eliminar jugador</Button> */}
              </Stack>
              <DataGrid
                    sx={{fontSize:12}}
                    autoHeight
                    rowHeight={38}
                    rows={jugadores}
                    columns={columns}
                    pageSize={15}
                    rowsPerPageOptions={[15]}
                    onRowClick={handleClickOnRow}
              />
              </Box>
          }
      {/* Dialog to eliminate user */}
      <Dialog
          open={anchorEliminateJugador}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Estás seguro de eliminar a este jugador?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cerrar</Button>
            <Button onClick={handleEliminateJugador} autoFocus>
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      {/* Modal to Create a User */}
          <Dialog fullWidth
                  maxWidth="md"  
                  onClose={handleClose} 
                  open={anchorCreateJugador}
                  >
              <CreateJugador  handleClose={handleClose} update={forceUpdate}/>
          </Dialog>
      {/* Modal to Edit a User */}
          <Dialog fullWidth
                  maxWidth="md"  
                  onClose={handleClose} 
                  open={anchorEditJugador}
                  >
              <EditJugador jugadorToEdit={jugadorSelected} handleClose={handleClose} update={forceUpdate}/>
          </Dialog>
      </Box>
    </Box>
    </Stack>
  )
}

export default JugadoresScouting;


const columns = [
  {field:"nombre", headerName:"Nombre",width:200},
  {field:"nacionalidad", headerName:"Nacionalidad",width:125},
  {field:"fec_nacimiento", headerName:"Fecha de Nacimiento", width:150},
  {field:"posicion", headerName:"Posición", width:125},
  {field:"pie", headerName:"Pie", width:100},
  {field:"estatura", headerName:"Estatura", width:100},
  {field:"equipo_actual", headerName:"Equipo", width:150},
  {field:"fecha_de_entrega", headerName:"Fecha de Entrega", width:150},
  {field:"similitud", headerName:"Similitud", width:150},
  {field:"descripcion", headerName:"Descripción", width:10000},
]
