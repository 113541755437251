import React from 'react'
import { Typography } from '@mui/material';

function Unauthorized() {
  return (
    <div style={styleContainer}>
      <div style={styleCard}>
        <Typography variant="overline" fontSize={40} >No autorizado</Typography>
        <Typography fontSize={28} >Si crees que existe un error, por favor envia un mensaje al correo: fhl@clubnecaxa.mx</Typography>
      </div>
    </div>
  )
}

export default Unauthorized;

const styleContainer={
  height : "100vh",
  display:"flex",
  justifyContent:"center",
  alignItems:"center"
}

const styleCard = {
  background: "#0A5B9A",
  width:"90%",
  maxWidth:"600px",
  height:"500px",
  textAlign:"center",
  color:"white",  
  display:"flex",
  flexDirection:"column",
  justifyContent:"center",
  alignItems:"center",
  padding:"0 20px 0 20px"

}