import React from 'react'
import { 
  Container,
  Typography,
  Stack,
  Paper,
  Divider,
  Grid,
  Box
} from '@mui/material'
import { useParams } from 'react-router-dom'
import PlayerCard from '../../features/PlayerCard/PlayerCard'
import { useEffect } from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../context/auth/AuthProvider'
import { useState } from 'react'
import RadarJugadores from '../Home/Jugadores/Radar'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function Jugador() { 
  const {playerId} = useParams()
  const {token, user} =useContext(AuthContext)
  const [jugador, setJugador] = useState();
  const [datos, setDatos] = useState();
 
  // state for the Radar
  const [ torneo, setTorneo ] = useState( "ei37yy9qltzgkbd3c15hq1d04");
  const [ torneoComp, setTorneoComp ] = useState( "ei37yy9qltzgkbd3c15hq1d04");
  const [ playersList, setPlayersList] = useState();
  const [playerComp, setPlayerComp] = useState("");
  const [playerCompData, setPlayerCompData] = useState();
  const [inputValuePC, setInputValuePC] = React.useState('');
  const [rows, setRows] = useState();
  // useEffect to get the general info of the player page
  useEffect(()=>{
    const fetchPlayer = async ()=>{
      const requestsOptions = {
        method:"GET",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +token
        }
      }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/players/${playerId}`,requestsOptions);
      if (response.ok){
        const data = await response.json()

        setJugador(data);
        
      }else{
        console.log("Hacer un error de cerrar sesión, esto pasa porque el token expiró")
      }
    }
    fetchPlayer();
  },[token, playerId])
 
  // useEffect to fetch player data
  useEffect(()=>{
    if(jugador){
      const fetchData = async ()=>{
        const requestsOptions = {
          method:"GET",
          headers:{
            "Content-Type":"application/json",
            Authorization: "Bearer " +token
          }
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/opta/players/?player_id=${jugador?.opta_id}&tournament_id=ei37yy9qltzgkbd3c15hq1d04`,requestsOptions);
        if (response.ok){
          const data = await response.json()
          setDatos(data);
         
  
        }else{
          console.log("")
        }
  
  
      }
  
      fetchData();   
    }
  },[jugador])

  // useEffect to fetch all the players in the torunament
  useEffect(()=>{
      const fetchData = async ()=>{
        const requestsOptions = {
          method:"GET",
          headers:{
            "Content-Type":"application/json",
            Authorization: "Bearer " +token
          }
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/opta/players/?tournament_id=${torneoComp}`,requestsOptions);
        if (response.ok){
          const data = await response.json()
          setPlayersList(data);
        }else{
          console.log("")
        }}
      fetchData();   
  },[torneoComp])

  // useEffect to fetch data for the 2 player - playerComp
  useEffect(()=>{
    if(playerComp){
      const fetchData = async ()=>{
        const requestsOptions = {
          method:"GET",
          headers:{
            "Content-Type":"application/json",
            Authorization: "Bearer " +token
          }
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/opta/players/?player_id=${playerComp?.player_id}&tournament_id=${torneoComp}`,requestsOptions);
        if (response.ok){
          const data = await response.json()
          setPlayerCompData(data);
        
        }else{console.log("Error Fetching the playerComp")}}
  
      fetchData();   
    }
  },[playerComp])


  useEffect(()=>{
    const rows = labels.map((label)=>{
      if(datos && playerCompData){
        return { label, label1: datos[label], label2: playerCompData[label] }
      }else if(datos){
        return { label, label1: datos[label], label2: "-" }
      }else{
        return { label, label1: "", label2: ""}
      }
  
    })
    setRows(rows)
  },[datos, playerCompData])

  return (
    <Container
    sx={{
      marginTop:8,
    }}
    >
      {/* rendering the component only when the player is loaded */}
      {jugador &&  
      <div>
          {/* Image and general information */}
          <Stack
            direction={{xs:"column",sm:"row",md:"row", lg:"row"}}
            justifyContent={{xs:"center", sm:"space-around" , md:"space-around",lg:"space-around"}}
            alignItems="center"
            sx={{
              marginTop:10,
              padding:{xs:0,sm:2,lg:3}
            }}
            >

              <PlayerCard jugador={jugador}/>
              {/* Información del jugador */}
              <Paper
              sx={{
                width:{xs:"100%",sm:"60%",md:"70%",lg:"70%",xl:"70%"},
                padding:2,
                height:"100%",
                marginTop:{xs:2, sm:0}
              }}
              elevation={5}
              >
                <Grid container>
                  <Grid item lg={12} sm={12} xs={12}> 
                    <Typography variant="h5" color="text.secondary" sx={{textAlign:"center", paddingY:2}}>{jugador.nombre}</Typography>
                    <Divider sx={{ marginX:10, marginBottom:1}}></Divider> 
                  </Grid>
                  <Grid item lg={6} >
                    <Typography variant="overline" display="inline" color="text.secondary" fontSize={15}>Posición:</Typography> <Typography fontSize={16} variant="subtitle1"display="inline">{jugador.posicion}</Typography>
                  </Grid> 
                  <Grid item lg={6} >
                    <Typography variant="overline" display="inline" color="text.secondary" fontSize={15}>Edad:</Typography><Typography fontSize={16} variant="subtitle1"display="inline"> {parseEdad(jugador.fec_nacimiento)} </Typography><Typography fontSize={12} variant="subtitle1"display="inline">  ({cleanDate(jugador.fec_nacimiento)})</Typography>
                  </Grid>
                  <Grid item lg={6} >
                    <Typography variant="overline" display="inline" color="text.secondary" fontSize={15}>Nacionalidad: </Typography><Typography fontSize={16} variant="subtitle1"display="inline">{jugador.nacionalidad}</Typography>
                  </Grid>
                  <Grid item lg={6} >
                    <Typography variant="overline" display="inline" color="text.secondary" fontSize={15}>Contrato: </Typography><Typography fontSize={16} variant="subtitle1"display="inline">{jugador.contrato_hasta || "Sin información"}</Typography>
                  </Grid>
                  <Grid item lg={8} >
                    <Typography variant="overline" display="inline" color="text.secondary" fontSize={15}>Lugar de nacimiento: </Typography><Typography fontSize={16} variant="subtitle1"display="inline">{jugador.lugar_nacimiento}</Typography>
                  </Grid>


                </Grid>

              </Paper>
          </Stack>
          {/* Charts of the player */}
          <Stack
                sx={{
                        marginTop:{xs:1, sm:1, md:1, lg:2,xl:2},
                        padding:{xs:0,sm:0,lg:3}
                      }}
                direction={{xs:"column",sm:"row", md:"row", lg:"row"}}
                justifyContent="space-around"
                alignItems="center"

          >
            <Paper
            sx={{
              paddingTop:{xs:0, sm:0, md:0, lg:1,xl:3},
              width:"100%",
              minHeight:"70vh",
            }}
            elevation={5}
            >   <Box
                  sx={{
                    maxHeight:"100%"
                  }}>
                    <Typography variant={"overline"} sx={{marginLeft:2, fontSize:14}} >Comparar Jugador</Typography>
                    <Divider></Divider>
                    <Paper
                      sx={{
                        marginX:{xs:1,sm:1,md:1, lg:4},
                        marginY:2,
                        paddingX:4,
                        paddingY:2,
                        display:"flex",
                        justifyContent:"space-around",
                        alignItems:"center",
                        flexDirection:{xs:"column",sm:"row",md:"row"}
                      }}
                      elevation={1}>
                      <Box
                        sx={{
                          display:"flex",
                          gap:2,
                          flexDirection:{xs:"column",sm:"column",md:"row"}
                          
                        }}
                        >
                        <FormControl sx={{   width: {xs:270,sm:300,md:200}}} size="small">
                          <InputLabel id="demo-select-small">Torneo</InputLabel>
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={torneo}
                            label="Torneo"
                            onChange={(event)=>{setTorneo(event.target.value)}}
                          >
                            <MenuItem value={"ei37yy9qltzgkbd3c15hq1d04"}>Apertura 2022</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl sx={{   width: {xs:270,sm:300,md:200} }} size="small">
                          <InputLabel id="selected-player">{ jugador.nombre} </InputLabel>
                          <Select
                            labelId="selected-player"
                            id="selected-player"
                            disabled
                            value=""
                            label="Jugador"
                            displayEmpty={true}
                          >

                          </Select>
                        </FormControl>
                      </Box>
                      <Box>
                        <Typography>
                          vs.
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display:"flex",
                          gap:2,
                          flexDirection:{xs:"column",sm:"column",md:"row"}
                        }}                        
                      >
                        <FormControl sx={{   width: {xs:270,sm:300,md:200} }} size="small">
                          <InputLabel id="demo-select-small">Torneo</InputLabel>
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={torneoComp}
                            label="Torneo"
                            onChange={(event)=>{setTorneoComp(event.target.value)}}
                          >
                            <MenuItem value={"ei37yy9qltzgkbd3c15hq1d04"}>Apertura 2022</MenuItem>
                          </Select>
                        </FormControl>
                        {
                          playersList &&
                            <Autocomplete
                              size="small"
                              onChange={(event, newValue) => {
                                setPlayerComp(newValue);
                              }}
                              // inputValue={inputValuePC}
                              // onInputChange={(event, newInputValue) => {
                              //   setInputValuePC(newInputValue);
                              // }}
                              options={playersList}
                              renderOption={(props, option) => {
                                return (
                                  <li {...props} key={option.player_id}>
                                    {option["Nombre completo"]}
                                  </li>
                                );
                              }}
                              getOptionLabel={(option)=>{
                                    return option["Nombre completo"]
                              }}
                              id="controllable-states-demo"
                              sx={{   width: {xs:270,sm:300,md:200} }}
                              renderInput={(params) => <TextField {...params} label="Jugador 2" />}
                            />
                        }
                      </Box>
                    </Paper>
                    {
                      jugador && datos &&
                      <Stack 
                      sx={{
                        flexDirection:{sm:"column",md:"row"},
                        justifyContent:{xs:"center",sm:"center", md:"space-between"},
                        alignItems:"center"
                      }}
                      >
                            <Box sx={{ width: {sm:"100%",md:"50%"} }}>
                            <TableContainer component={Paper} sx={{paddingY:2,width:{xs:"95%",sm:"95%",md:"90%"}, marginX:"auto", marginTop:{sm:2,md:5}}}> 
                            <Table  size="small" aria-label="a dense table">
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={{fontSize:{xs:16,sm:18,md:20}}} >Métrica</TableCell>
                                  <TableCell sx={{fontSize:{xs:16,sm:18,md:20}}} align="right">{datos.Nombre}</TableCell>
                                  <TableCell sx={{fontSize:{xs:16,sm:18,md:20}}} align="right">{playerCompData ? playerCompData.Nombre : "-"}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow >
                                  <TableCell sx={{color:"red", fontWeight:"bold"}}> Minutos Jugados</TableCell>
                                  <TableCell sx={{color:"red", fontWeight:"bold"}} align="right">{datos["Minutos jugados"]}</TableCell>
                                  <TableCell sx={{color:"red", fontWeight:"bold"}} align="right">{playerCompData ? playerCompData["Minutos jugados"] : "-"}</TableCell>
                                </TableRow>
                                {rows && rows.map((row) => (
                                  <TableRow
                                    key={row.label}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {row.label}
                                    </TableCell>
                                    <TableCell align="right">{row.label1}</TableCell>
                                    <TableCell align="right">{row.label2}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                            </Box>
                            <Box sx={{ width: {xs:"100%",sm:"75%",md:"50%"}, marginTop:{xs:3,sm:1,md:0} }}>
                                 <RadarJugadores player_data={datos} player_comp={playerCompData} />
                            </Box>
                      </Stack>
                    }
                    <Typography variant='overline' sx={{marginLeft:1}}>Datos: Opta.</Typography>  
                </Box>

            </Paper>
          </Stack>  
      </div>
      }

    </Container>
  )
}

export default Jugador

const parseEdad = (date)=>{
    const diff_ms = Date.now()- (new Date(cleanDate(date))).getTime()
    const age_dt = new Date(diff_ms)
    return Math.abs(age_dt.getUTCFullYear() - 1970) + " años";
}

const cleanDate = (date)=>{
  let pattern = /\d+-\d+-\d+/
  return date.match(pattern)
}

const tournaments = {
  "Apertura 2022": "ei37yy9qltzgkbd3c15hq1d04",
}


const labels =[
  "Recuperaciones/90",
  "Pases/90",
  "Regates/90",
  "Regates exitosos, %",
  "Goles/90",
  "Duelos ganados, %",
  "Centros exitosos, %"
]