import React from 'react'
import {
    Paper,
    Typography,
    Divider
} from '@mui/material'


function MetricaChip({metric, value}) {
  return (
    <Paper
    elevation={10}
    sx={{
        marginTop:1,
        padding:1,
        height:70,
        display:"flex",
        flexDirection:"column",
        justifyContent:"center"
    }}>
        <div>
        <Typography variant="body2" display={"inline"}>Métrica:  </Typography><Typography variant="body2"display={"inline"} color="primary">{metric}</Typography>
        </div>
        <Divider />
        <div>
        <Typography variant="body2" display={"inline"}>Peso:  </Typography><Typography variant="body2"display={"inline"} color="primary">{value}</Typography>
        </div>
    </Paper>
  
  )
}

export default MetricaChip