import React from 'react'
import { 
  Container,
  Typography,
  Stack,
  Paper,
  Divider,
  Grid,
  Box
} from '@mui/material'
import { useParams } from 'react-router-dom'
import TeamCard from '../../features/TeamCard/TeamCard'
import { useEffect } from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../context/auth/AuthProvider'
import { useState } from 'react'
import RadarJugadores from '../Home/Jugadores/Radar'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useOpta } from '../../context/opta/OptaProvider'
import MultiSelectList from '../../features/MultiSelectList/MultiSelectList'
import Loader from '../../components/Loader/Loader'
import ScatterChartEquipo from './ScatterChartEquipo'
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Equipo() {
  const {equipoId} = useParams();
  const {columnsTeams} = useOpta();
  const { token, user} = useContext(AuthContext);
  const [equipo, setEquipo] = useState();

  const [torneo, setTorneo] = useState("AP2022");

  const [metricaX, setMetricaX] = useState("Pases");
  const [metricaY, setMetricaY] = useState("Tiros");
  const [data, setData ] = useState();

  const [players, setPlayers] = useState();

  // useEffect to get the team info
  useEffect(()=>{
    const fetchTeam = async ()=>{
      const requestsOptions = {
        method:"GET",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +token
        }
      }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/teams/${equipoId}`,requestsOptions);
      if (response.ok){
        const data = await response.json()

        setEquipo(data);
        
      }else{
        console.log("Hacer un error de cerrar sesión, esto pasa porque el token expiró")
      }


    }
    fetchTeam();

  },[equipoId])
  

  // useEffect to get the data for all the tams from these two metrics
  useEffect(()=>{
        const fetchData = async ()=>{
            const requestsOptions = {
            method:"GET",
            headers:{
                "Content-Type":"application/json",
                Authorization: "Bearer " +token
            }
            }
            const response = await fetch(`${process.env.REACT_APP_API_URL}/opta/teams/scatter/?tournament_id=${torneo}&metric_x=${metricaX}&metric_y=${metricaY}`,requestsOptions);
            if (response.ok){
            const data = await response.json()
            setData(data);
            }else{
            console.log("Error Fetching Data in Equipos Page")
            }}

        fetchData();   
    },[metricaX, metricaY])

  // useEffect to get the data for all the tams from these two metrics
  useEffect(()=>{
    const fetchData = async ()=>{
        const requestsOptions = {
        method:"GET",
        headers:{
            "Content-Type":"application/json",
            Authorization: "Bearer " +token
        }
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/opta/players/?tournament_id=${torneo}&team_id=${equipo?.opta_id}`,requestsOptions);
        if (response.ok){
        const data = await response.json()
        setPlayers(data);
        }else{
        console.log("Error Fetching Data in Equipos Page-Players")
        }}

    fetchData();   
},[equipoId, token, equipo])

  console.log(players)
  return (
    <Container
    sx={{
      marginTop:8,
    }}
    >
      {
        equipo && 
      <div>
      {/* Image and general information */}
      <Stack
      direction={{xs:"column",sm:"row", md:"row", lg:"row"}}
      justifyContent={{xs:"center", sm:"space-around" , md:"space-around",lg:"space-around"}}
      alignItems="center"
      sx={{
        marginTop:10,
        padding:{xs:0,sm:2,lg:3}
      }}
      >

        {/* Team Card */}
        <TeamCard equipo={equipo}/>
        {/* Información del jugador */}
        <Paper
        sx={{
          width:{xs:"100%",sm:"70%",md:"70%",lg:"70%",xl:"70%"},
          padding:2,
          height:"100%",
          marginTop:{xs:2, sm:0}
        }}
        elevation={5}
        >
          <Typography variant='h2' sx={{textAlign:"center"}}>{equipo.nombre}</Typography>
        </Paper>
      </Stack>
      {/* Charts of the teams */}
      <Stack
            sx={{   marginTop:{xs:1, sm:1, md:1, lg:2,xl:2},
                    padding:{xs:0,sm:0,lg:3}}}
            direction={{xs:"column",md:"row", lg:"row"}}
            justifyContent="space-around"
            alignItems="center">
            <Paper
              sx={{ paddingTop:{xs:0, sm:0, md:0, lg:1,xl:3},
                    width:"100%",
                    minHeight:"80vh"}}
              elevation={5}
            >   <Box
                  sx={{
                    maxHeight:"100%"
                  }}>
                    <Typography variant={"overline"} sx={{marginLeft:2, fontSize:14}} >Comparar Equipo</Typography>
                    <Divider></Divider>
                    <Paper
                      sx={{
                        marginX:{xs:1,sm:1,lg:4},
                        marginY:2,
                        paddingX:4,
                        paddingY:2,
                        display:"flex",
                        justifyContent:"space-around",
                        alignItems:"center",
                        flexDirection:{xs:"column",sm:"row",md:"row"}
                      }}
                      elevation={1}>
                      <Box
                        sx={{
                          display:"flex",
                          gap:2,
                          flexDirection:{xs:"column",sm:"row",md:"row"},
                          marginTop:2
                          
                        }}
                        >
                        <FormControl sx={{   width: {xs:270,sm:200,md:200}}} size="small">
                          <InputLabel size={"small"} id="demo-select-small">Torneo</InputLabel>
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={torneo}
                            label="Torneo"
                            onChange={(event)=>{setTorneo(event.target.value)}}
                          >
                            <MenuItem value={"AP2022"}>Apertura 2022</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <Box
                        sx={{
                          display:"flex",
                          gap:{xs:1,sm:1,md:2},
                          flexDirection:{xs:"column",sm:"row",md:"row"},
                          alignItems:"center",
                          marginTop:2
                        }}                        
                        >
                        {/* Select de Métrica X */}
                        {
                          columnsTeams.length>0 &&
                                  <FormControl fullWidth sx={{   width: {xs:270,sm:200,md:200}}} size="small">
                                    <InputLabel size={"small"} id="demo-simple-select-label">{"Eje X"}</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={metricaX}
                                      label={"Eje X"}
                                      onChange={(event)=>setMetricaX(event.target.value)}
                                      size="small"
                                      
                                    >
                                        {
                                            columnsTeams.map((option)=>(
                                                <MenuItem  key={option} value={option}>{option}</MenuItem>
                                            ))

                                        }
                                    </Select>
                                  </FormControl>

                        }
                       
                        <Box>
                          <Typography>
                            vs.
                          </Typography>
                        </Box>
                        {/* Select de Métrica Y*/}
                        {/* Select de Métrica X */}
                        {
                          columnsTeams.length>0 &&
                                  <FormControl fullWidth sx={{   width: {xs:270,sm:200,md:200}}}>
                                    <InputLabel size={"small"} id="demo-simple-select-label">{"Eje Y"}</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={metricaY}
                                      label={"Eje Y"}
                                      onChange={(event)=>setMetricaY(event.target.value)}
                                      size="small"
                                     
                                    >
                                        {
                                            columnsTeams.map((option)=>(
                                                <MenuItem  key={option} value={option}>{option}</MenuItem>
                                            ))

                                        }
                                    </Select>
                                  </FormControl>

                        }
                      </Box>
                    </Paper>
                    <Box 
                        sx={{
                          maxWidth:"100%",
                          marginTop:{sm:0,md:1},
                        }}>
                      {
                        data ?

                        <ScatterChartEquipo data={data} x_label={metricaX} y_label={metricaY} team_opta_id={equipo.opta_id}/>
                        :
                        <Loader show={true} />
                      }
                
                    </Box>
                    <Typography variant='overline' sx={{marginLeft:1}}>Datos: Opta.</Typography>  
                </Box>
            </Paper>
      </Stack>  
      {/* Squad */}

      <Box 
          sx={{
              marginY:1,
              padding:{sm:0,md:0,lg:3}
          }}
          >
              <Box
              sx={{
                  display:"flex",
                  direction:"row"
              }}>
              <Typography sx={{width:"100%", textAlign:"center" ,background:"red", paddingY:1, paddingX:2, color:"white", fontSize:{xs:20,sm:28,md:28}}}>Plantel</Typography>
              </Box>
              {
                players && players?.length>1 ?
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650, marginX:1 }} aria-label="caption table">
                  <caption>Última actualización: </caption>
                  <TableHead>
                    <TableRow>
                      <TableCell  >Nombre</TableCell>
                      <TableCell align="right">Posición</TableCell>
                      <TableCell align="right">Nacionalidad</TableCell>
                      <TableCell align="right">Partidos jugados</TableCell>
                      <TableCell align="right">Minutos jugados</TableCell>
                      <TableCell align="right">Titularidades</TableCell>
                      <TableCell align="right">Asistencias</TableCell>
                      <TableCell align="right">Goles</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {players.map((player) => (
                      <TableRow key={player.Nombre}>
                        <TableCell padding='none' component="th" scope="row">
                          {player.Nombre}
                        </TableCell>
                        <TableCell padding='none' align="right">{player.position}</TableCell>
                        <TableCell padding='none' align="right">{player.nationality}</TableCell>
                        <TableCell padding='none' align="right">{player["Partidos jugados"]}</TableCell>
                        <TableCell padding='none' align="right">{player["Minutos jugados"]}</TableCell>
                        <TableCell padding='none' align="right">{player["Titularidades"]}</TableCell>
                        <TableCell padding='none' align="right">{player["Goles"]}</TableCell>
                        <TableCell padding='none' align="right">{player["Asistencias"]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              :
              <Loader show="True" />
              }

      </Box>
      {/* <Box 
          sx={{
              marginY:1,
              padding:{sm:0,md:0,lg:3}
          }}
          >
              <Box
              sx={{
                  display:"flex",
                  direction:"row"
              }}>
              <Typography sx={{width:"100%", textAlign:"center" ,background:"red", paddingY:1, paddingX:2, color:"white", fontSize:{xs:20,sm:20,md:28}}}>Plantel</Typography>
              </Box>
              {
              false && 
              <Box sx={{ width: '100%', marginTop:0 }}>
              <DataGrid
                    autoHeight
                    rows={players}
                    columns={columns}
                    pageSize={23}
                    rowsPerPageOptions={[23]}
              />
              </Box>
          }

      </Box> */}
      </div>
      }
    </Container>
  )
}

export default Equipo;


const columns = [
  {field:"Nombre", headerName:"Nombre", flex: .7,width:150},
  {field:"position", headerName:"Posición", flex: 1,width:150},
  {field:"nationality", headerName:"Nacionalidad",flex: .3, width:150},
  {field:"Partidos jugados", headerName:"Partidos jugados", flex: 1,width:150},
  {field:"Minutos jugados", headerName:"Minutos jugados",flex: 1, width:200},
  {field:"Titularidades", headerName:"Titularidades", flex: 1,width:200},
  {field:"Goles", headerName:"Goles", flex: 1,width:200},
  {field:"Asistencias", headerName:"Asistencias", flex: 1,width:200},
]