import React, { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { 
  Paper,
  Box,
  Typography,

} from '@mui/material';

import RadarTestsBasicas from './RadarTestsBasicas'


function AreaRadarBasicas() {
  const {jugadorId}=useParams();

  return (
    <Paper
    elevation={5}
    sx={{
      width:{xs:"100%",sm:"95%",md:"95%",lg:"95%",xl:"95%"},
      marginX:"auto",
      marginTop:5,
      minHeight:400,
      display:"flex",
      flexDirection:{xs:"column", sm:"row",m:"row",lg:"row",xl:"row"},
      justifyContent:"space-around",
      alignItems:"center",
      paddingY:5,

    }}
    >
      <Box
      sx={{

      }}
      >
          <RadarTestsBasicas/>

        
      </Box>  


    </Paper>
  )
}

export default AreaRadarBasicas