import React from 'react'
import SideBar from '../../components/SideBar/SideBar'

function Visorias() {
  return (
    <div>
      <SideBar>
        Visorías
      </SideBar>

    </div>
  )
}

export default Visorias