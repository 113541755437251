import React from 'react'
import {
    Stack,
    Typography,
    Box,
    Grid
} from '@mui/material'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';


function AnalisisEntrenadores() {


  const handleClickOnCard = (clave) =>{
    handleDownloadPDF(clave);
  }

  const handleDownloadPDF = (clave)=>{
    // using Java Script method to get PDF file
    fetch(`https://necaxa-analisis.s3.us-west-1.amazonaws.com/analisis/scouting/entrenadores/analisis/${clave}.pdf`).then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = `${clave}.pdf`;
            alink.click();
        })
    })
}

  return (
    <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
        marginY:{xs:1, sm:1, md:1,lg:10,xl:10},
        width:"100%"}}>
      <Box width={300} sx={{backGround:"black" ,display:{xs:"none",sm:"none", md:"block"},}}></Box>
      
      <Box 
        sx={{
            width:"100%",
            marginX:{md:0,xl:8,lg:8},
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center"

        }}
      >
    <Typography variant="h3" sx={{textAlign:"center", }}>Análisis de Entrenadores</Typography>
    <Grid container spacing={3} sx={{marginTop:5, marginX:"auto", width:"100%"}} minHeight={"70vh"}>
    {
        presentaciones.map((presentacion)=>{
            return (
              <Grid key={presentacion.clave} item xs={12} md={4}>
                <Card sx={{ minWidth:300, maxWidth: 345, height:425}}  elevation={5}>
                  <CardActionArea  onClick={()=>handleClickOnCard(presentacion.clave)}>
                    <CardMedia
                      component="img"
                      height="100"
                      image={`https://necaxa-analisis.s3.us-west-1.amazonaws.com/analisis/scouting/entrenadores/analisis/${presentacion.clave}.png`}
                      alt={presentacion.clave}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {presentacion.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {presentacion.fecha}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary" onClick={()=>handleClickOnCard(presentacion.clave)}>
                      Descargar Presentación
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              );
        })
    }
    </Grid>
    </Box>
    </Stack>
  )
}

export default AnalisisEntrenadores;


const presentaciones = [
    {
        name: "Jaime Lozano",
        fecha:"01/01/2022",
        descripción:"Entregada por Jaime Lozano.",
        clave:"Jaime Lozano"
    },
    {
        name: "Ruben Amorim",
        fecha:"01/01/2022",
        descripción:"Entregada por Ruben Amorim.",
        clave:"Ruben Amorim"
    },

]