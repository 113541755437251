import React, {useState} from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Box,
    IconButton,
    Stack,
    Typography,
    Paper,
    Button,
    Divider,
    Grid
}
from '@mui/material'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';


function ARPage({setAnalisisOpen, jornada, temporada, torneo}) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const videoLink = `https://necaxa-analisis.s3.us-west-1.amazonaws.com/analisis/rival/temporada/${temporada}/${torneo}/${jornada.clave}.mp4`
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
    // handler to prevent the download of the video
    const handleClickOnVideo = (e)=>{
        e.preventDefault();
    }

    //handler to go back
    const handleClickBack = ()=>{
        setAnalisisOpen(false);
    }

    const handleDownloadPDF = (type)=>{
        // using Java Script method to get PDF file
        fetch(`https://necaxa-analisis.s3.us-west-1.amazonaws.com/analisis/rival/temporada/${temporada}/${torneo}/${jornada.clave}.pdf`).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = `${jornada.clave}-Análisis del Rival.pdf`;
                alink.click();
            })
        })
    }
  return (
    <Box sx={{width:"90%"}}>
        <Stack direction="row" sx={{ justifyContent:"space-between"}}spacing={2}>
            <Button onClick={handleClickBack} variant="outlined" startIcon={<ArrowBackIcon />}>
                Atrás
            </Button>

        </Stack>
    <Box
    sx={{
        marginY:3
    }}
    >
        <Box 
        sx={{
            marginBottom:2
        }}
        >
            <Box
            sx={{
                display:"flex",
                direction:"row"
            }}>
            <Typography sx={{background:"red", paddingY:1, paddingX:2, color:"white", fontSize:{xs:12,sm:16,md:28}}}>{torneo}</Typography>
            <Divider orientation="vertical" flexItem sx={{marginX:.5}}/>
            <Typography sx={{width:"50%",background:"red", paddingY:1, paddingX:2, color:"white", textAlign:"center",fontSize:{xs:12,sm:16,md:28}}}>{jornada.clave}</Typography>
            <Divider orientation="vertical" flexItem sx={{marginX:.5}}/>
            <Typography sx={{ paddingY:1, paddingX:2,  fontSize:{xs:12,sm:16,md:28}, width:"100%", textAlign:"center", outline:"1px solid gray"}}>{jornada.Rival}</Typography>
            </Box>

        </Box>
        <video onContextMenu={(e)=>{handleClickOnVideo(e)}} controls controlsList="nodownload" width="100%">
        
            <source src={videoLink} type="video/mp4" />
            Sorry, your browser doesn't support videos.
        </video>
    </Box>
    <Box 
        sx={{
            marginBottom:2
        }}
        >
            <Box
            sx={{
                display:"flex",
                direction:"row"
            }}>
            <Typography sx={{width:"100%", textAlign:"center" ,background:"red", paddingY:1, paddingX:2, color:"white", fontSize:{xs:12,sm:16,md:28}}}>REPORTES</Typography>
            </Box>
    <Box
    sx={{
        marginTop:3
    }}>
        <Button fullWidth onClick={()=>handleDownloadPDF("Match Report")}  color="info" variant="outlined" endIcon={<SimCardDownloadIcon />}>
        Reporte del Rival
        </Button>
    </Box>
    </Box>
    </Box>

  )
}

export default React.memo(ARPage);
