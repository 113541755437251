import React from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { useParams } from 'react-router-dom'
import {useEffect, useContext, useState} from 'react'
import { AuthContext } from '../../../../context/auth/AuthProvider';


ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export default function RadarTestsBasicas() {
    const {jugadorId}=useParams();
    const {token, user} = useContext(AuthContext)
    const [testData, setTestData] = useState();

    // useEffect to ask for the test scores of the last tests
    useEffect(()=>{ 
        const fetchLastTests = async ()=>{
        const requestsOptions = {
            method:"GET",
            headers:{
            Authorization: "Bearer " + token
            }
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/tests/scores/${jugadorId}`, requestsOptions)
        if(response.ok){
            const data = await response.json()
            // API responds with an object, inside Scores are the actual scores in an obj.
            setTestData(data.Scores);
        }else{
            console.log("Este error se produjo en RadarTestsBasicas")
        }

        }

        fetchLastTests();
    },[jugadorId])

    let data_scores;
    if (testData){
        data_scores={
            labels: ["Preparación Física","Psicología","Nutrición","Técnica","Medicina"],
            datasets:[
                {

                    label: 'Último test',
                    data:[testData["Preparación Física"],testData["Psicología"],testData["Nutrición"], testData["Técnica"], testData["Medicina"]],
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                }
            ]
        }
    }
    const options = {
        scales: {
            r: {
                beginAtZero: true,
                min: 0,
                max: 10,
                ticks: {
                    stepSize: 2
                }
                
          },
        }}
    
    // const options = {
    //     scales: {
    //       r: {
    //         beginAtZero: true,
    //         min:  0,
    //         max:  10, 
    //       },
    //     },
    //   };


    return (

                data_scores &&
                <Radar data={data_scores}  options={options}/>

    )
;
}
