import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../context/auth/AuthProvider';
import {
    Box,
    TextField,
    Typography,
    Stack,
    Button
} from '@mui/material';
import Switch from '@mui/material/Switch';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';




function EditJugador({jugadorToEdit, handleClose, update}) {
    const {user, token} = useContext(AuthContext)
    

    const [nombre, setNombre] = useState(jugadorToEdit?.nombre);
    const [generales, setGenerales] = useState(jugadorToEdit?.generales);
    const [valor, setValor] = useState(jugadorToEdit?.valor);
    const [otrosDatos, setOtrosDatos] = useState(jugadorToEdit?.otros_datos);
    const [caracteristicas, setCaracteristicas] = useState(jugadorToEdit?.caracteristicas);
    const [similitud, setSimilitud] = useState(jugadorToEdit?.similitud);
    


  // función para crear mandar una petición POST y crear un jugador
  const handleEditJugador = ()=>{
    if(nombre){
        const putJugador = async ()=>{
            const requestOptions = {
              method: "PUT",
              headers:{
                "Content-Type":"application/json",
                Authorization: "Bearer " + token,},
              body:JSON.stringify({
                "nombre": nombre,
                "valor": valor,
                "generales": generales,
                "caracteristicas": caracteristicas,
                "otros_datos": otrosDatos,
                "similitud":similitud
              })
            }
            const response = await fetch(`${process.env.REACT_APP_API_URL}/jugadoresscouting/${jugadorToEdit?.id}`, requestOptions)
            if (response.ok){
                const data = await response.json()
                setNombre("");
                setGenerales("");
                setValor("");
                setOtrosDatos("");
                setCaracteristicas("");
                setSimilitud("")
                handleClose();
                update();

                console.log("Jugador Actualizado")
            }
  
          }
  
          putJugador();
    }else{
        alert("Por favor llena todos los campos")
    }
  }

  return (
    <Box
    sx={{
        padding:{xs:2,sm:2,md:6,lg:6},
        width:"100%",
        position:"relative"
    }}>
        <Typography variant='h4'>Editar Jugador</Typography>
        <TextField required value={nombre} onChange={(event)=>setNombre(event.target.value)} fullWidth placeholder='Nombre' sx={{marginY:1}}/>
        <TextField required value={valor} onChange={(event)=>setValor(event.target.value)} fullWidth placeholder='Valor' sx={{marginY:1}}/>
        <TextField required value={generales} onChange={(event)=>setGenerales(event.target.value)} fullWidth placeholder='Generales' sx={{marginY:1}}/>
        <TextField required value={otrosDatos} onChange={(event)=>setOtrosDatos(event.target.value)} fullWidth placeholder='Otros Datos' sx={{marginY:1}}/>
        <TextField required value={caracteristicas} onChange={(event)=>setCaracteristicas(event.target.value)} fullWidth placeholder='Caracteristicas' sx={{marginY:1}}/>
        <TextField required value={similitud} onChange={(event)=>setSimilitud(event.target.value)} fullWidth placeholder='Similitud' sx={{marginY:1}}/>
        
        

        {/* Buttones to cancel */}
        <Stack 
        justifyContent={"space-between"}
        direction={{sm:"column", md:"row"}}>
            <Button variant="contained" onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleEditJugador} sx={{marginTop:{xs:2,sm:2,md:0}}} variant="contained" color="success">Editar usuario</Button>
        </Stack>
    </Box>
  )
}

export default EditJugador;