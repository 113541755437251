import React, {useState, useEffect, useContext} from 'react'
import { Box, TableHead, TableRow, Paper, TableBody, Table, TableCell, TableContainer, Typography } from '@mui/material'
import MultiSelectList from '../../../../features/MultiSelectList/MultiSelectList'
import { AuthContext } from '../../../../context/auth/AuthProvider'
import { useParams } from 'react-router-dom'
import LineChartBasicas from '../LineChartBasicas'


function AreaTests() {
    const [departamentoSelected, setDepartamentoSelected] = useState("Preparación Física")
    const [variables, setVariables] = useState()
    const [variableSelected, setVariableSelected] = useState("")
    const {token, user} = useContext(AuthContext)
    const {jugadorId} = useParams();

    const departamentos = ["Preparación Física", "Nutrición", "Psicología", "Técnico", "Medicina", "Portero"]
    const mapDep = {"Preparación Física": "preparacionfisica", "Nutrición":"nutricion", "Psicología":"psicologia", "Técnico":"tecnico", "Medicina":"medicina", "Portero":"portero"}

    const [tests, setTests] = useState();



    //  useEffect to call for the tests from this particular department
    useEffect(()=>{
        const fetchTests = async()=>{
            const requestOptions = {
                method:"GET",
                headers:{
                    Authorization: "Bearer " + token
                }
    
            } 
            // get the player name
            const responsePlayer = await fetch(`${process.env.REACT_APP_API_URL}/players/${jugadorId}`, requestOptions)
            const dataPlayer = await responsePlayer.json()

            const response = await fetch(`${process.env.REACT_APP_API_URL}/tests?department=${mapDep[departamentoSelected]}&player_name=${dataPlayer.nombre}`,requestOptions)
            if (response.ok){
                const data = await response.json()
                setTests(data);
                // check if there is tests in the data
                if(data.length >0){
                    setVariables(Object.keys(data[0]).filter(e=>{if(e!="categoria" & e!="player_name" & e!= "id" & e!="created_at"){return e}}))
                }else{
                    setVariables(null)
                }
            }
        }

        fetchTests();


    },[jugadorId,departamentoSelected])

    // useEffect to changue the variable selected each time that the list changes
    useEffect(()=>{
        if(variables){
            setVariableSelected(variables[0])
        }else{
            setVariableSelected("")
        }
    },[variables, departamentoSelected])

  return (
    <Box
    sx={{
        width:{xs:"100%",sm:"95%",md:"95%",lg:"95%",xl:"95%"},
        marginX:"auto",
        marginY:8,
        paddingY:2
      }}
    >   
        {/* Multiselect para elegir un departamento */}
        <MultiSelectList targetList={departamentos} label="Deparamento"  value={departamentoSelected} setValue={setDepartamentoSelected} sx={{width:"100%", margin:0}}/>

        {         

            tests && tests.length !=0 ?
            <>
            {/* Table for all tests of a department */}
            <Box
            sx={{
                minHeight:200,
                display:"flex",
                flexDirection:"column",
                justifyContent:"space-between",
                alignItems:"center",
                marginTop:8,
            }}
            >
                <TableContainer component={Paper} sx={{maxWidth:{xs:330,sm:750,md:1100,lg:1100, xl:1600}}}>
                    <Table aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            {Object.keys(tests[0]).map((header)=>(
                                <TableCell key={header} align="left">{header}</TableCell>
                            ))}

                            
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {tests.map((test)=>(

                            <TableRow
                            key={test.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {
                                    Object.keys(test).map((value)=>(
                                
                                        <TableCell key={value} align="right">{test[value]}</TableCell>
                                    ))

                                }


                            </TableRow>

                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>

            {/* Multiselect para elegir una variable */}
            {/* Lo comenté porque no era necesario, en caso de errores lo tengo disponible, si no lo borraré al final */}
            {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-helper-label">Variable</InputLabel>
                <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={variableSelected}
                label="Variable"
                onChange={event =>setVariableSelected(event.target.value)}
                >
                {
                    variables.map((option)=>(
                        <MenuItem  key={option} value={option}>{option}</MenuItem>
                    ))

                }
                </Select>

            </FormControl> */}   
            <MultiSelectList targetList={variables} label="Variable"  value={variableSelected} setValue={setVariableSelected} sx={{width:"100%", marginY:3,marginX:0}}/>


            {/* Line Chart con los datos de un test */}
            <LineChartBasicas tests={tests} variableSelected={variableSelected} />
            

            </>
        :
        
        <Typography variant="h4" sx={{marginY:10, width:"100%", textAlign:"center"}}>No hay Tests disponibles</Typography>    
        }


        {/* Mensaje alterno si el jugador en cuestión no tiene tests */}

    </Box>
  )
}

export default AreaTests