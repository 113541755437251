import React, { useState, useEffect } from 'react'
import {
    Typography,
    Divider,
    Box,
    List,
    Stack,
    Slider,
    Button,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Paper
} from '@mui/material'
import SideBar from '../../../../components/SideBar/SideBar'
import { useOpta } from '../../../../context/opta/OptaProvider'
import { useAuth } from '../../../../context/auth/AuthProvider'
import Loader from '../../../../components/Loader/Loader'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MetricaChip from './MetricaChip'
import * as XLSX from 'xlsx'
import MultiSelectList from '../../../../features/MultiSelectList/MultiSelectList'
import { useReducer } from 'react'
import { DataGrid } from '@mui/x-data-grid';

function RankingJugadores() {
    const { columns } = useOpta();
    const { token } = useAuth();
    const [minMinutos, setMinMinutos] = useState(0) 
    const [rankings, setRankings] = useState();
    const [rankingSelected, setRankingSelected] = useState("");
    const [metricsSelected, setMetricsSelected] = useState();
    //files states
    const [file, setFile] = useState("");
    const [excelData, setExcelData] = useState()
    const [excelMetrics, setExcelMetrics] = useState();

    // states of modals
    const [ openEliminate, setOpenEliminate ] = useState(false);
    const [ openEditRanking, setOpenEditRanking ] = useState(false);
    const [ openCreateRanking, setOpenCreateRanking ] = useState(false);
    
    // states for creating and editing ranking 
    const [ rankingName, setRankingName ] = useState("");
    function reducer(state, action){
      switch(action.type){
        case 'add':
          return {...state, ...action.payload }
        case 'remove':
            delete state[action.metric]
            return {...state };
        case 'remove-all':
            return {};
        case 'add-all':
            return action.metrics;
        default:
          break;
      }
    }
    const initialState = {}
    const [ metricsState, metricsDispatch ] = useReducer(reducer, initialState);
    const [ newMetric, setNewMetric ] = useState("")
    const [ newValue,  setNewValue ] = useState(1)
    const [ rankingData, setRankingData ] = useState();
    const [ excelColumns, setExcelColumns ] = useState();
    const [ position, setPosition ] = useState("");
    const handleEliminateFile = ()=>{
      setFile("");
      setExcelData();
      setExcelMetrics();
    }

    const hanleOnClickInput= (e)=>{
      e.target.value = ''
      setFile("");
    }
    //  upload files
    const handleUploadFile = (e)=>{
          const file = e.target.files[0];
          if(!file) return;
          setFile(e.target.files[0])
          let reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload=(e)=>{
            const workbook = XLSX.read(e.target.result,{type:"buffer"})
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const data = XLSX.utils.sheet_to_json(worksheet)
            setExcelData(data);
            setExcelMetrics(Object.keys(data[0]))
          }
        }
    // function to fetch the data from players ranking
    useEffect(()=>{
      if(rankingSelected && file){
        const handleSendFile = ()=>{
          if( file && rankingSelected ){
            let formData = new FormData()
            formData.append('file', file)
            const sendFile = async ()=>{
              const requestOptions = {
                method: "POST",
                headers:{
                  Authorization: "Bearer " + token,},
                  body: formData
              }
              const response = await fetch(`${process.env.REACT_APP_API_URL}/rankings/wyscout/${rankingSelected?.id}?min_minutos=${minMinutos}&posicion=${position}`, requestOptions)
              if (response.ok){
                  const data = await response.json()
                  setRankingData(data)
                  let columns = Object.keys(data[0])
                  .filter(value=> value != "id" ? value : "")
                  .map((column)=>{ return { field: column, headerName: column, width: 150  } })
                  setExcelColumns(columns)
                }
            }
            sendFile();}
        }
        handleSendFile();
      }
    },[rankingSelected, file, excelColumns])

    // useEffect to fetch Rankings
    useEffect(()=>{
      const fetchRankings = async ()=>{
        const requestsOptions = {
          method:"GET",
          headers:{
            "Content-Type":"application/json",
            Authorization: "Bearer " +token
          }
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/rankings?provider=WyScout`,requestsOptions);
        if (response.ok){
          const data = await response.json()
          setRankings(data);
        }else{
          console.log("Hacer un error de cerrar sesión, esto pasa porque el token expiró")
        }}
      fetchRankings();  
    },[])

    // handle CRUD Operations
    const handleClickCrear = ()=>{
      if(!excelMetrics){
        alert("Necesitas cargar un archivo de Excel para Crear un Ranking")
      }else{
        setOpenCreateRanking(true);
      }
    }
    const handleClickEliminate = ()=>{
      if(!rankingSelected){
        alert("Necesitas Elegir un Ranking")
      }else{
        setOpenEliminate(true)
      }
    }
    const handleClickEdit = ()=>{
      if(!rankingSelected || !file){
        alert("Necesitas Elegir un Ranking Y Subir un Archivo de Excel")
      }else{
        setRankingName(rankingSelected.name)
        metricsDispatch({type:"add-all", metrics: JSON.parse(rankingSelected.metrics.replace(/'/g, '"'))});
        setOpenEditRanking(true)
      }}
    const handleClose = ()=>{
      setOpenEliminate(false);
      setOpenEditRanking(false);
      setOpenCreateRanking(false);
      setNewMetric("")
      setNewValue(1)
    }

    const handleClickAgregarMetrica = () =>{
      // Revisar si la métrica ya está dentro de las méticas
      if(Object.keys(metricsState).includes(newMetric)){
        alert("Esta métrica ya está dentro del Ranking")
      }else if(newMetric === ""){
        alert("Elige una métrica")
      }
      else{
        let payload = {}
        payload[newMetric] = newValue
        metricsDispatch({type:"add", payload: payload});
        setNewMetric("")
        setNewValue(1)
      }
    }
    const handleEliminateMetric = (event)=>{ metricsDispatch({type:"remove", metric: event.currentTarget.value}); }

    const handlePOSTRanking = ()=>{
      if(rankingName != "" && Object.keys(metricsState).length>0){
        const postRanking = async ()=>{
          const requestOptions = {
            method: "POST",
            headers:{
              "Content-Type":"application/json",
              Authorization: "Bearer " + token,},
            body:JSON.stringify({
              "name": rankingName,
              "provider": "WyScout",
              "metrics": JSON.stringify(metricsState)
            })
          }
          const response = await fetch(`${process.env.REACT_APP_API_URL}/rankings`, requestOptions)
          if (response.ok){
              const data = await response.json()
              setRankingName("");
              metricsDispatch({type:"remove-all"});
              let newArrayRankings = [...rankings,data.data ]
              setRankings(newArrayRankings)
              handleClose();
              console.log("Ranking creado")
          }
        }
        postRanking();
      }else{
        alert("Debes de Completar todos los campos")
      }
    }
    const handlePUTRanking = ()=>{
      if(rankingName != "" && Object.keys(metricsState).length>0){
        const putRanking = async ()=>{
          const requestOptions = {
            method: "PUT",
            headers:{
              "Content-Type":"application/json",
              Authorization: "Bearer " + token,},
            body:JSON.stringify({
              ...rankingSelected,
              "name": rankingName,
              "metrics": JSON.stringify(metricsState)
            })
          }
          const response = await fetch(`${process.env.REACT_APP_API_URL}/rankings/${rankingSelected.id}`, requestOptions)
          if (response.ok){
              const data = await response.json()
              setRankingName("");
              metricsDispatch({type:"remove-all"});
              let newArrayRankings = rankings.filter(ranking=> ranking.id != rankingSelected.id)
              newArrayRankings.push({...rankingSelected,"name": rankingName,"metrics": JSON.stringify(metricsState)})
              setRankings(newArrayRankings)
              setRankingSelected("")
              handleClose();
              console.log("Ranking Editado")
          }
        }
        putRanking();
      }else{
        alert("Debes de Completar todos los campos")
      }
    }
    const handleDELETERanking = () =>{
      if( rankingSelected){
        const postRanking = async ()=>{
          const requestOptions = {
            method: "DELETE",
            headers:{
              "Content-Type":"application/json",
              Authorization: "Bearer " + token}
          }
          const response = await fetch(`${process.env.REACT_APP_API_URL}/rankings/${rankingSelected.id}`, requestOptions)
          if (response.ok){
              const data = await response.json()
              let newArrayRankings = rankings.filter(ranking=> ranking.id != rankingSelected.id)
              setRankings(newArrayRankings)
              setRankingSelected("")
              handleClose()
              console.log("Ranking Eliminado")
          }
        }
        postRanking();
      }

    }

    const handleOnExport = ()=>{
      var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(rankingData)

      XLSX.utils.book_append_sheet(wb, ws, "Ranking");

      XLSX.writeFile(wb, "Ranking.xlsx")
    }
  return (
    <Box
    sx={{
      maxWidth:"100vw"
    }}
    >
    <SideBar>
      {/* Subir archivo */}
      <List         
       sx={{
            marginTop:10,
            marginBottom:1,
            width:210

        }}>
          <Typography variant={"overline"} fontSize={18} sx={{ color:"red" }}>Sube un Archivo</Typography>
          <Divider />
          <Button fullWidth variant="contained" component="label" sx={{marginY:1}}>
              Subir Excel
              <input  hidden onClick={hanleOnClickInput} onChange={(e)=>handleUploadFile(e)} accept=".xlsx"  type="file" />
            </Button>
            {
              file &&
              <ul>
                <li
                style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}
                >
                  <Typography>{file.name}</Typography>
                  <IconButton onClick={handleEliminateFile}>
                      <DeleteForeverIcon  />
                  </IconButton>
                  
                  </li>
              </ul>
            }
      <Button size="small" fullWidth onClick={handleClickCrear} color="success" variant="contained" sx={{marginBottom:3}}>
          Crear Ranking    
      </Button>
      {
        rankings ?
        <div>
              <FormControl sx={{   width: {xs:270,sm:300,md:210}}} size="small">
                <InputLabel id="demo-select-small">Ranking</InputLabel>
                 <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={rankingSelected}
                      label="Ranking"
                      onChange={(event)=>{
                        setMetricsSelected(JSON.parse(event.target.value.metrics.replace(/'/g, '"')))
                        setRankingSelected(event.target.value);
                        }}>
                  {
                    rankings.map((ranking)=>{
                      return <MenuItem key={ranking.id} value={ranking}>{ranking.name}</MenuItem>
                    })

                  }

                  </Select>
              </FormControl>
        </div>
        :
        <Typography>Crear un Ranking </Typography>

      }     
            <Box
            sx={{
              marginY:3,
        
            }}>

            {/* Filtro de Minutos Jugados */}
            <Typography variant={"overline"} fontSize={16} sx={{ color:"red" }}>Filtros</Typography>
            <Divider sx={{ marginTop:0}}/>
          <Typography variant={"overline"} fontSize={12} sx={{color:"black"}}>Minutos Jugados: {minMinutos}</Typography>
          <Box 
          sx={{
            maxWidth:"90%",
            marginX:"auto"
          }}>
            <Slider
              aria-label="Temperature"
              defaultValue={0}
              getAriaValueText={()=>minMinutos}
              valueLabelDisplay="auto"
              color="secondary"
              onChangeCommitted={(event, value)=>{setMinMinutos(value)}}
              step={1}
              marks
              min={0}
              max={2000}
            />
          </Box>
          {/* Filtro de Posición */}
          <Typography variant={"overline"} fontSize={12} sx={{color:"black"}}>Posición</Typography>
          <FormControl sx={{   width: {xs:270,sm:300,md:210}, marginTop:1}} size="small">
                <InputLabel id="demo-select-small">Posición</InputLabel>
                 <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={position}
                      label="Posición"
                      onChange={(event)=>{
                        setPosition(event.target.value);
                        }}>
                  {
                    posiciones.map((posicion)=>{
                      return <MenuItem key={posicion} value={posicion}>{posicion}</MenuItem>
                    })

                  }

                  </Select>
          </FormControl>
          </Box>
      {
        rankingSelected &&
        <Box
        sx={{
          marginTop:2
        }}>
          {
            Object.keys(metricsSelected).map((metric)=>{
              return <MetricaChip key={metric} metric={metric} value={metricsSelected[metric]}/>
            })
          }
        </Box>
      }
      <Button size="small" fullWidth onClick={handleClickEdit} color="secondary" variant="contained" sx={{marginTop:3}}>
          Editar Ranking    
      </Button>
      <Button size="small" fullWidth onClick={handleClickEliminate} color="primary" variant="contained" sx={{marginTop:1}}>
          Eliminar Ranking    
      </Button>

      </List>
      
    </SideBar>
      {/* Box for mobile devices */}
    <Box
      marginTop={10}
      background="black"
      marginX={"auto"}
      width={"90%"}
      display={{
        xs:"block",
        sm: "block",
        md:"block",
        lg:"none",
        xl:"none"
      }}>
        <Stack
              direction="row"
              sx={{
                display:{xs:"flex", sm:"flex", md:"none"},
                marginTop:1,
                justifyContent:"space-around"
              }} >
        </Stack>
    </Box>
    <Box 
        sx={{
          maxWidth:"90%",
          marginLeft:{sm:0,md:30},
          marginTop:{sm:0,md:10},
        }}>
    {/* Aquí van las fichas de Ranking */}
    {
      rankingData ?
      <Box 
      sx={{
        margin:"auto",
        paddingX:4,
        width:"100%"
      }}>
        <Box sx={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
          <Typography variant="h3">Ranking</Typography>
          <Button variant="outlined" onClick={handleOnExport}>Descargar Ranking</Button>
        </Box>
              
      {excelColumns &&
        <Box sx={{ height: 600, width: '100%', marginTop:4 }}>
          <DataGrid
            rows={rankingData}
            columns={excelColumns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            disableSelectionOnClick
          />
        </Box>}
      </Box>
      :
      <Box
      sx={{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        height:{sm:"50vh",md:"90vh"},
        width:{xs:"100vw",sm:"100%",md:"50%"},
        marginX:"auto",
        textAlign:"center"
      }}
      >
        <Box
        sx={{
          boxShadow:"1px 1px 10px gray, -1px -1px 10px gray",
          padding:{sm:3, md:4},
          border:"none",
          // borderRadius:"5px",
          marginX:"auto",
          width:"90%"
        }}>
          <Typography variant="h2">Sube un Archivo de Excel y Elige un Ranking</Typography>
        </Box>
      </Box>
    }
    </Box>
    {/* Dialog to Elimnate Ranking */}
    <Dialog
        open={openEliminate}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estás seguro que quieres eliminar este Ranking?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
          <Button onClick={ handleDELETERanking }>
            Eliminar
          </Button>
        </DialogActions>
    </Dialog>  
    {/* Dialog To Create Ranking */}
    <Dialog fullWidth
                maxWidth="sm"  
                onClose={handleClose} 
                open={openCreateRanking}
                scroll={"body"}
                >
        <Box 
          sx={{
            paddingY:{xs:2,sm:2,md:5,lg:5},
            paddingX:{xs:2,sm:2,md:1,lg:1},
            width:"90%",
            position:"relative",
            overflow:"hidden",
            marginX:"auto"
          }}
          >
            <Typography variant="h3" sx={{textAlign:"center"}}> Crear Ranking</Typography>
            <Stack 
            sx={{display:"flex", marginTop:2}}>
              <TextField  sx={{ marginX:"auto", width:"100%"}} value={rankingName} onChange={(event)=>setRankingName(event.target.value)}  placeholder="Nombre del Ranking" variant="filled" margin="none" ></TextField>
              {
                Object.keys(metricsState) &&
                Object.keys(metricsState).map((metric)=>{
                  
                  return (
                  <Paper key={metric} elevation={10} sx={{padding:1, marginTop:2}}>
                    <Stack sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", gap:3}}>
                      <FormControl fullWidth>
                        <InputLabel  id="demo-simple-select-label">{metric}</InputLabel>
                        <Select
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={metric}
                          label={metric}
                          disabled
                        >
                          <MenuItem value={metric}>{metric}</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel  id="demo-simple-select-label">Peso</InputLabel>
                        <Select
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={metricsState[metric]}
                          label="Peso"
                          disabled
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                        </Select>
                      </FormControl>
                    <IconButton  value={metric} onClick={(event)=>handleEliminateMetric(event)}>
                      <DeleteForeverIcon  />
                    </IconButton>
                    </Stack>

                  </Paper>
                  )
                })
              }
              <Paper elevation={10} sx={{padding:1, marginTop:5}}>
                <Stack sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", gap:3}}>
                  <MultiSelectList sx={{width:"100%"}} size="small" targetList={excelMetrics} value={newMetric} setValue={setNewMetric} label="Agregar Métrica" />
                  <FormControl fullWidth>
                    <InputLabel  id="demo-simple-select-label">Peso</InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={newValue}
                      label="Peso"
                      onChange={(e)=>{setNewValue(e.target.value)}}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Button sx={{ marginX:"auto", marginTop:1}} fullWidth size="small"  onClick={handleClickAgregarMetrica} color="primary" variant="contained">
                    Agregar Métrica
                </Button>
              </Paper>
              <Button sx={{ marginX:"auto", marginTop:5}} fullWidth size="small"  onClick={handlePOSTRanking} color="success" variant="contained">
                    Crear Ranking
              </Button>
            </Stack>
            
        </Box>     
    </Dialog>
    {/* Dialog To Edit Ranking */}
    <Dialog fullWidth
                maxWidth="md"  
                onClose={handleClose} 
                open={openEditRanking}
                >
        <Box sx={{
            padding:{xs:2,sm:2,md:6,lg:6},
            marginBottom:4,
            width:"100%",
            position:"relative",
            overflow:"hidden"}}>
                          <Typography variant="h3" sx={{textAlign:"center"}}> Editar Ranking</Typography>
            <Stack 
            sx={{display:"flex", marginTop:2}}>
              <TextField  sx={{ marginX:"auto", width:"100%"}} value={rankingName} onChange={(event)=>setRankingName(event.target.value)}  placeholder="Nombre del Ranking" variant="filled" margin="none" ></TextField>
              {
                Object.keys(metricsState) &&
                Object.keys(metricsState).map((metric)=>{
                  
                  return (
                  <Paper key={metric} elevation={10} sx={{padding:1, marginTop:2}}>
                    <Stack sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", gap:3}}>
                      <FormControl fullWidth>
                        <InputLabel  id="demo-simple-select-label">{metric}</InputLabel>
                        <Select
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={metric}
                          label={metric}
                          disabled
                        >
                          <MenuItem value={metric}>{metric}</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel  id="demo-simple-select-label">Peso</InputLabel>
                        <Select
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={metricsState[metric]}
                          label="Peso"
                          disabled
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                        </Select>
                      </FormControl>
                    <IconButton  value={metric} onClick={(event)=>handleEliminateMetric(event)}>
                      <DeleteForeverIcon  />
                    </IconButton>
                    </Stack>

                  </Paper>
                  )
                })
              }
              <Paper elevation={10} sx={{padding:1, marginTop:5}}>
                <Stack sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", gap:3}}>
                  <MultiSelectList sx={{width:"100%"}} size="small" targetList={excelMetrics} value={newMetric} setValue={setNewMetric} label="Agregar Métrica" />
                  <FormControl fullWidth>
                    <InputLabel  id="demo-simple-select-label">Peso</InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={newValue}
                      label="Peso"
                      onChange={(e)=>{setNewValue(e.target.value)}}>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Button sx={{ marginX:"auto", marginTop:1}} fullWidth size="small"  onClick={handleClickAgregarMetrica} color="primary" variant="contained">
                    Agregar Métrica
                </Button>
              </Paper>
              <Button sx={{ marginX:"auto", marginTop:5}} fullWidth size="small"  onClick={handlePUTRanking} color="success" variant="contained">
                    Editar Ranking
              </Button>
            </Stack>
        </Box>      
    </Dialog>
    </Box>
  )
}

export default RankingJugadores

const tournaments = {"Apertura 2022": "ei37yy9qltzgkbd3c15hq1d04"}

const posiciones = ['CB', 'GK', 'DMF', 'LB', 'LWB', 'CF', 'LCB', 'RCB', 'RB', 'LW',
'RCMF', 'RW', 'RDMF', 'LCMF', 'LDMF', 'RWB', 'AMF', 'RAMF', 'LAMF',
'LWF', 'RWF']