import { Container, Box, Stack, Grid, Paper, Typography, Divider } from '@mui/material';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router-dom'
import {AuthContext} from '../../../context/auth/AuthProvider'
import PlayerCard from '../../../features/PlayerCard/PlayerCard'
import AreaRadarBasicas from './AreaRadarBasicas/AreaRadarBasicas';
import AreaTests from './AreaTests/AreaTests';

import AccordionTests from './AccordionTests';

function JugadorBasicas() {
  const {token, user} = useContext(AuthContext)
  const [jugador, setJugador] = useState();

  const {jugadorId}=useParams();

  useEffect(()=>{
    const fetchPlayer = async ()=>{
      const requestsOptions = {
        method:"GET",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +token
        }
      }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/players/${jugadorId}`,requestsOptions);
      if (response.ok){
        const data = await response.json()
        setJugador(data);

      }else{
        console.log("Hacer un error de cerrar sesión, esto pasa porque el token expiró")
      }


    }

    fetchPlayer();



  },[token, jugadorId, user])

  
  return (
    <Stack
    direction="row"
    sx={{
      marginTop:{sm:0,md:0,lg:2},
    }}
    >
      <Box
      width={{ lg:310, xl:240}}
      sx={{
        background:"black",
        display:{xs:"none",sm:"block", md:"block"}
      }}
      >
      </Box>
        {      
          jugador &&
          <Stack
          sx={{
            marginTop:{sm:0,lg:10},
            padding:3
          }}>
            <Stack
              direction={{xs:"column",sm:"row", lg:"row"}}
              justifyContent={{xs:"center", sm:"space-around" , md:"space-around",lg:"space-around"}}
              alignItems="center"

              >
                
                <PlayerCard jugador={jugador}/>
                {/* Información del jugador */}
                <Paper
                sx={{
                  width:{xs:"100%",sm:"60%",md:"70%",lg:"70%",xl:"70%"},
                  padding:2,
                  height:"100%",
                  marginTop:{xs:2, sm:2}
                }}
                elevation={5}
                >
                  <Grid container>
                    <Grid item lg={12} sm={12} xs={12}> 
                      <Typography variant="h5" color="text.secondary" sx={{textAlign:"center", paddingY:2}}>{jugador.nombre}</Typography>
                      <Divider sx={{ marginX:{md:2,lg:10,xl:10}, marginBottom:1}}></Divider> 
                    </Grid>
                    <Grid item lg={6} sm={12} xs={12}>
                      <Typography variant="overline" display="inline" color="text.secondary" fontSize={15}>Posición:</Typography> <Typography fontSize={16} variant="subtitle1"display="inline">{jugador.posicion}</Typography>
                    </Grid> 
                    <Grid item lg={6} sm={12} xs={12}>
                      <Typography variant="overline" display="inline" color="text.secondary" fontSize={15}>Edad:</Typography><Typography fontSize={16} variant="subtitle1"display="inline"> {parseEdad(jugador.fec_nacimiento)} </Typography><Typography fontSize={12} variant="subtitle1"display="inline">  ({cleanDate(jugador.fec_nacimiento)})</Typography>
                    </Grid>
                    <Grid item lg={6} sm={12} xs={12}>
                      <Typography variant="overline" display="inline" color="text.secondary" fontSize={15}>Nacionalidad: </Typography><Typography fontSize={16} variant="subtitle1"display="inline">{jugador.nacionalidad}</Typography>
                    </Grid>
                    <Grid item lg={6} sm={12} xs={12}>
                      <Typography variant="overline" display="inline" color="text.secondary" fontSize={15}>Contrato: </Typography><Typography fontSize={16} variant="subtitle1"display="inline">{jugador.contrato_hasta || "Sin información"}</Typography>
                    </Grid>
                    <Grid item lg={8} sm={12} xs={12}>
                      <Typography variant="overline" display="inline" color="text.secondary" fontSize={15}>Lugar de nacimiento: </Typography><Typography fontSize={16} variant="subtitle1"display="inline">{jugador.lugar_nacimiento}</Typography>
                    </Grid>


                  </Grid>

                </Paper>
            </Stack>
            <AreaRadarBasicas/>
            <AreaTests/>
            {/* Info de departamentos */}
            <Box
            sx={{
              marginX:{sm:1, lg:5}
            }}
            >
              <Typography sx={{textAlign:"center", marginBottom:3}} variant={"h5"}>Información sobre los tests</Typography>
              <AccordionTests></AccordionTests>

      
            </Box>
          </Stack>
        
        }


    </Stack>
  )
}

export default JugadorBasicas;

const parseEdad = (date)=>{
  const diff_ms = Date.now()- (new Date(cleanDate(date))).getTime()
  const age_dt = new Date(diff_ms)
  return Math.abs(age_dt.getUTCFullYear() - 1970) + " años";
}

const cleanDate = (date)=>{
let pattern = /\d+-\d+-\d+/
return date.match(pattern)
}