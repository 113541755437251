import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from 'react';

export default function MultiSelectPlayers({targetList, label,setValue}) {
  const [optionValue, setOptionValue] = useState(targetList[0].id);
  const handleChange = (event) => {
    setValue(event.target.value);
    setOptionValue(event.target.value)
  };
  React.useEffect(()=>{
    setValue(targetList[0].id);
    setOptionValue(targetList[0].id);
  },[targetList])


  return (
    <Box sx={{ width:{xs:150,sm:250,  md:220}, marginY:2 }}>
      <FormControl fullWidth>
        <InputLabel id="players-select-label">{label}</InputLabel>
        <Select
          labelId="players-select-label"
          id="players-select"
          value={optionValue}
          label={label}
          onChange={handleChange}
        >
            {
                targetList && 

                     targetList.map((jugador)=>(
                    <MenuItem id={jugador.id} key={jugador.nombre} value={jugador.id}>{jugador.nombre}</MenuItem>
                    ))

            }
        </Select>
      </FormControl>
    </Box>
  );
}