import React from 'react'
import {
    Stack,
    Typography,
    Box,
    Grid
} from '@mui/material'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';


function AnalisisEspeciales() {


  const handleClickOnCard = (clave) =>{
    handleDownloadPDF(clave);
  }

  const handleDownloadPDF = (clave)=>{
    // using Java Script method to get PDF file
    fetch(`https://necaxa-analisis.s3.us-west-1.amazonaws.com/analisis/especiales/${clave}.pdf`).then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = `${clave}.pdf`;
            alink.click();
        })
    })
}

  return (
    <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
        marginY:{xs:10, sm:10, md:10,lg:10,xl:10},
        width:"100%"}}>
    
      <Box 
        sx={{
            width:"100%",
            marginX:{md:0,xl:8,lg:8},
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center"

        }}
      >
    <Typography variant="h3" sx={{textAlign:"center", }}>Trabajos Especiales</Typography>
    <Grid container spacing={3} sx={{marginTop:5, marginX:"auto"}} minHeight={"70vh"}>
    {
        presentaciones.map((presentacion)=>{
            return (
              <Grid key={presentacion.clave} item xs={12} sm={6} md={4} lg={3}>
                <Card sx={{ minWidth:300, maxWidth: 345, height:425}}  elevation={5}>
                  <CardActionArea  onClick={()=>handleClickOnCard(presentacion.clave)}>
                    <CardMedia
                      component="img"
                      height="100"
                      image={`https://necaxa-analisis.s3.us-west-1.amazonaws.com/analisis/especiales/${presentacion.clave}.png`}
                      alt={presentacion.clave}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {presentacion.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {presentacion.fecha}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary" onClick={()=>handleClickOnCard(presentacion.clave)}>
                      Descargar Presentación
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              );
        })
    }
    </Grid>
    </Box>
    </Stack>
  )
}

export default AnalisisEspeciales;


const presentaciones = [
    {
        name: "Multi-Club Ownership",
        fecha:"",
        clave:"MCO"
    },
    {
        name: "Lineamientos para la formación del plantel del primer equipo",
        fecha:"Diciembre 2021",
        clave:"LP-E"
    },
    {
        name: "Guidelines for the formation of the first team squad",
        fecha:"Diciembre 2021",
        clave:"LP-I"
    },
    {
      name: "Featured homegrown players",
      fecha:"Octubre 2021",
      clave:"FHP"
  },
  {
    name: "Set Pieces Project",
    fecha:"Abril 2022",
    clave:"SPP"
},
{
  name: "Políticas deportivas de conformación de plantel",
  fecha:"",
  clave:"PDCP"
},
{
  name: "Indicadores de Rendimiento Clave",
  fecha:"",
  clave:"KPIs"
},
{
  name: "Proyecto Tutores - Lateral",
  fecha:"",
  clave:"PT-L"
},
{
  name: "Proyecto Tutores - Contención",
  fecha:"",
  clave:"PT-C"
},

]