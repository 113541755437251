import React from 'react'
import { Navigate, useLocation, Outlet } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '../../context/auth/AuthProvider'
import Loader from '../../components/Loader/Loader'


function ProtectedRoute({nivelAuth, departmentsAuth,children}) {
    const {token, user, loading} = useContext(AuthContext);
    

    const location = useLocation();
    if (!token){
        return <Navigate to="/login" state={{from: location}} replace/>
    }

    if(loading){
        return <Loader show={loading}/>
    }else{
        if(user?.nivel >= nivelAuth || departmentsAuth.includes(user?.departamento)){
            return children
        }else{
            return <Navigate to="/unauthorized" state={{from: location}} replace/>
        }
    }

}

export default ProtectedRoute