import React from 'react'
import { Box,  } from '@mui/material'
import { useNavigate } from 'react-router-dom'

function TeamCard({equipo}) {
  
    return (
      <Box
      component="div"
      sx={buttonStyle}
      data-equipoid={equipo.id}
      key={equipo.id}
      >
            <Box
              key={equipo.id}
              data-equipoid={equipo.id}
              component="img"
              sx={{
                height: {xs:75,sm:100,lg:100,xl:100},
                width: "auto",
                maxHeight: { xs: 233, sm: 167 },
                maxWidth: { xs: 350, sm: 250 },
              }}
              alt={equipo.nombre}
              src={`https://necaxa-analisis.s3.us-west-1.amazonaws.com/images/equipos/${equipo.id}.png`}
            />
      </Box>
  
    )
  }
  
  export default TeamCard;
  
  const buttonStyle ={
    outline:"none",
    border:"none",
    boxShadow:"3px 3px 10px gray, -3px -3px 10px gray",
    padding:1
  }