import React from 'react'
import { Box, Typography } from '@mui/material'
import Default from '../../assets/images/players/default.jpeg'


function PlayerCard({jugador}) {

  return (
    <Box
    component="div"
    sx={buttonStyle}
    data-playerid={jugador.id}
    key={jugador.id}
    >
          <Box
            key={jugador.id}
            data-playerid={jugador.id}
            component="img"
            sx={{
              height: {xs:275,sm:275,lg:275,xl:275},
              width: "auto",
              maxHeight: { xs: 275, md: 275 }
            }}
            alt={jugador.nombre}
            src={`https://necaxa-analisis.s3.us-west-1.amazonaws.com/images/jugadores/${jugador.id}.jpg`}
          />
    </Box>

  )
}

export default PlayerCard;

const buttonStyle ={
  outline:"none",
  border:"none",
  boxShadow:"3px 3px 10px gray, -3px -3px 10px gray",
}