import React from 'react'
import {
    Stack,
    Typography,
    Box,
    Grid
} from '@mui/material'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';


function AnalisisManuales() {


  const handleClickOnCard = (clave) =>{
    handleDownloadPDF(clave);
  }

  const handleDownloadPDF = (clave)=>{
    // using Java Script method to get PDF file
    fetch(`https://necaxa-analisis.s3.us-west-1.amazonaws.com/analisis/manuales/${clave}.pdf`).then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = `${clave}.pdf`;
            alink.click();
        })
    })
}

  return (
    <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
        marginY:{xs:10, sm:10, md:10,lg:10,xl:10},
        width:"100%"}}>
    
      <Box 
        sx={{
            width:"100%",
            marginX:{md:0,xl:8,lg:8},
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center"

        }}
      >
    <Typography variant="h3" sx={{textAlign:"center", }}>Manuales de Trabajo</Typography>
    <Grid container spacing={3} sx={{marginTop:5, marginX:"auto"}} minHeight={"70vh"}>
    {
        presentaciones.map((presentacion)=>{
            return (
              <Grid key={presentacion.clave} item xs={12} sm={6} md={4}>
                <Card sx={{ minWidth:300, maxWidth: 345, maxHeight:345}}  elevation={5}>
                  <CardActionArea  onClick={()=>handleClickOnCard(presentacion.clave)}>
                    <CardMedia
                      component="img"
                      height="100"
                      image={`https://necaxa-analisis.s3.us-west-1.amazonaws.com/analisis/manuales/${presentacion.clave}.png`}
                      alt={presentacion.clave}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {presentacion.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {presentacion.fecha}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary" onClick={()=>handleClickOnCard(presentacion.clave)}>
                      Descargar Presentación
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              );
        })
    }
    </Grid>
    </Box>
    </Stack>
  )
}

export default AnalisisManuales;


const presentaciones = [
    {
        name: "Manual de Analista - A",
        fecha:"",
        clave:"MAA"
    },
    {
        name: "Manual de Analista - B",
        fecha:"",
        clave:"MAB"
    },
    {
        name: "Manual de Grabaciones",
        fecha:"",
        clave:"MG"
    },
    {
      name: "Manual de Scouting",
      fecha:"",
      clave:"MS"
  }
]