import React, {useState} from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Box,
    IconButton,
    Stack,
    Typography,
    Paper,
    Button,
    Divider,
    Grid
}
from '@mui/material'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import ImagePlace from '../../../assets/images/Picture1.png'
import useAuth from '../../../hooks/useAuth';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useEffect } from 'react';


function EjercicioPage({setEjercicioOpen, ejercicio}) {
    const { user, token } = useAuth();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const videoLink = `https://necaxa-analisis.s3.us-west-1.amazonaws.com/basicas/ejercicios-mp4/${ejercicio.clave}.mp4`



  //files states
  const [file, setFile] = useState("");
  const hanleOnClickInput= (e)=>{
        e.target.value = ''
        setFile("");
      }
  //  upload files
  const handleUploadFile = (e)=>{
            const file = e.target.files[0];
            if(!file) return;
            setFile(e.target.files[0])
            let reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload=(e)=>{
            }
           
          
          }
  useEffect(()=>{
    handleSendFile(ejercicio.clave)
  },[file])


    const handleSendFile = (id)=>{
        if( file ){
        console.log(id)
        let formData = new FormData()
        formData.append('file', file)
        const sendFile = async ()=>{
          const requestOptions = {
            method: "POST",
            headers:{
              Authorization: "Bearer " + token,},
              body: formData
          }
          const response = await fetch(`${process.env.REACT_APP_API_URL}/ejercicios/uploadpdf/${id}`, requestOptions)
          if (response.ok){
              const data = await response.json()
            }
        }
        sendFile();}
    }
    
      //image states
  const [image, setImage] = useState("");
  const hanleOnClickInputImage= (e)=>{
        e.target.value = ''
        setFile("");
      }
  //  upload image
  const handleUploadImage = (e)=>{
            const file = e.target.files[0];
            if(!file) return;
            setImage(e.target.files[0])
            let reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload=(e)=>{
            }
           
          
          }
  useEffect(()=>{
    handleSendImage(ejercicio.clave)
  },[image])


    const handleSendImage = (id)=>{
        if( file ){
        let formData = new FormData()
        formData.append('file', file)
        const sendFile = async ()=>{
          const requestOptions = {
            method: "POST",
            headers:{
              Authorization: "Bearer " + token,},
              body: formData
          }
          const response = await fetch(`${process.env.REACT_APP_API_URL}/ejercicios/uploadimg/${id}`, requestOptions)
          if (response.ok){
              const data = await response.json()
            }
        }
        sendFile();}
    }
   
  
    //video states
  const [video, setVideo] = useState("");
  const hanleOnClickInputVideo= (e)=>{
        e.target.value = ''
        setVideo("");
      }
  //  upload video
  const handleUploadVideo = (e)=>{
            const file = e.target.files[0];
            if(!file) return;
            setVideo(e.target.files[0])
            let reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload=(e)=>{
            }
           
          
          }
  useEffect(()=>{
    handleSendVideo(ejercicio.clave)
  },[video])


    const handleSendVideo = (id)=>{
        if( video ){
        let formData = new FormData()
        formData.append('file', video)
        const sendVideo = async ()=>{
          const requestOptions = {
            method: "POST",
            headers:{
              Authorization: "Bearer " + token,},
              body: formData
          }
          const response = await fetch(`${process.env.REACT_APP_API_URL}/ejercicios/uploadvideo/${id}`, requestOptions)
          if (response.ok){
              const data = await response.json()
            }
        }
        sendVideo();}
    }


    // handler to prevent the download of the video
    const handleClickOnVideo = (e)=>{
        e.preventDefault();
    }

    //handler to go back
    const handleClickBack = ()=>{
        setEjercicioOpen(false);
    }

    const handleDownloadPDF = ()=>{
        
        // using Java Script method to get PDF file
        const fetchFile = async ()=>{
            const response = await fetch(`https://necaxa-analisis.s3.us-west-1.amazonaws.com/basicas/ejercicios-pdf/${ejercicio.clave}.pdf`)
            if (response.ok){
                const blob = await response.blob()
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = `${ejercicio.clave}.pdf`;
                alink.click();
            }
            else{
                alert("No hay un PDF para este ejercicio")
            }
        }
        fetchFile();

        // fetch(`https://necaxa-analisis.s3.us-west-1.amazonaws.com/basicas/ejercicios-pdf/${ejercicio.clave}.pdf`)
        // .then(response => {
        //     console.log(response)
        //     response.blob().then(blob => {
        //     console.log(blob.size)
        //         // Creating new object of PDF file
        //         const fileURL = window.URL.createObjectURL(blob);
        //         // Setting various property values
        //         let alink = document.createElement('a');
        //         alink.href = fileURL;
        //         alink.download = `${ejercicio.clave}.pdf`;
        //         alink.click();
        //     })})
    }
  return (
    <Box sx={{width:"90%"}}>
        <Stack direction="row" sx={{ justifyContent:"space-between"}}spacing={2}>
            <Button onClick={handleClickBack} variant="outlined" startIcon={<ArrowBackIcon />}>
                Atrás
            </Button>
            <div>
            {
                user.nivel == 6 &&
            <Button sx={{marginRight:3}} color="success" variant="contained" component="label">
                Subir Archivo (PDF)
            <input  hidden onClick={hanleOnClickInput} onChange={(e)=>handleUploadFile(e)} accept=".pdf"  type="file" />
          </Button>

            }
            <Button onClick={handleDownloadPDF}  color="info" variant="outlined" endIcon={<SimCardDownloadIcon />}>
                Descargar Archivo
            </Button>
            </div>
        </Stack>
    <Box
    sx={{
        marginY:3
    }}
    >
        <Box 
        sx={{
            marginBottom:2
        }}
        >
            <Box
            sx={{
                display:"flex",
                direction:"row"
            }}>
            <Typography sx={{background:"red", paddingY:1, paddingX:2, color:"white", fontSize:{xs:12,sm:16,md:28}}}>CLAVE</Typography>
            <Divider orientation="vertical" flexItem sx={{marginX:.5}}/>
            <Typography sx={{width:"50%",background:"red", paddingY:1, paddingX:2, color:"white", textAlign:"center",fontSize:{xs:12,sm:16,md:28}}}>{ejercicio.clave}</Typography>
            <Divider orientation="vertical" flexItem sx={{marginX:.5}}/>
            <Typography sx={{ paddingY:1, paddingX:2,  fontSize:{xs:12,sm:16,md:28}, width:"100%", textAlign:"center", outline:"1px solid gray"}}>FUERZAS BÁSICAS</Typography>
            </Box>

        </Box>
        {/* {
                user.nivel == 6 &&
                <Box
                sx={{
                    diplay:"flex",
                    justifyContent:"center",
                    width:"100%",
                    padding:2
                }}>
                <Button sx={{margin:"auto"}} color="success" variant="contained" component="label">
                        Subir Video (MP4)
                    <input  hidden onClick={hanleOnClickInputVideo} onChange={(e)=>handleUploadVideo(e)} accept=".mp4"  type="file" />
                </Button>
                </Box>

            } */}
        <video onContextMenu={(e)=>{handleClickOnVideo(e)}} controls controlsList="nodownload" width="100%">
        
            <source src={videoLink} type="video/mp4" />
            Sorry, your browser doesn't support videos.
        </video>
        {/* Grid de información del ejercicio */}
        <Grid container spacing={2} sx={{marginY:{xs:0,sm:0,md:2}}}>
            <Grid item xs={12} md={4} >
                <div style={{display:"flex"}}>
                    <Typography sx={{width:"50%", background:"#DAD8D4", paddingY:.5, paddingX:1, color:"black", fontSize:{xs:12,sm:16,md:16}}}>TIPO DE TRABAJO</Typography>
                    <Typography sx={{width:"50%", paddingY:.5, paddingX:1, color:"black", fontSize:{xs:12,sm:16,md:16}}}>{ejercicio.tipo}</Typography>
                </div>
                <div style={{display:"flex", marginTop:".5rem"}}>
                    <Typography sx={{width:"50%",display:"inline", background:"#DAD8D4", paddingY:.5, paddingX:1, color:"black", fontSize:{xs:12,sm:16,md:16}}}>CARGA</Typography>
                    <Typography sx={{width:"50%", display:"inline",paddingY:.5, paddingX:1, color:"black", fontSize:{xs:12,sm:16,md:16}}}>{ejercicio.carga}</Typography>
                </div>
                <Grid container sx={{marginTop:".5rem", justifyContent:"space-between", maxWidth:"70%"}}>
                        <Grid item xs={3} md={3}>
                            <Typography sx={{display:"inline", background:"#DAD8D4", paddingY:.5, paddingX:1, color:"black", fontSize:{xs:12,sm:16,md:14}}}>TIEMPO</Typography>
                            <Typography sx={{ display:"inline",paddingY:.5, paddingX:.5, color:"black", fontSize:{xs:12,sm:16,md:14}}}>{ejercicio.tiempo}</Typography>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <Typography sx={{display:"inline", background:"#DAD8D4", paddingY:.5, paddingX:1, color:"black", fontSize:{xs:12,sm:16,md:14}}}>SERIES</Typography>
                            <Typography sx={{ display:"inline",paddingY:.5, paddingX:.5, color:"black", fontSize:{xs:12,sm:16,md:14}}}>{ejercicio.series}</Typography>
                        </Grid>
                </Grid>
                <div style={{display:"flex", marginTop:".5rem"}}>
                        <Typography sx={{display:"inline", background:"#DAD8D4", paddingY:.5, paddingX:1, color:"black", fontSize:{xs:12,sm:16,md:14}}}>RECUPERACIÓN</Typography>
                        <Typography sx={{ display:"inline",paddingY:.5, paddingX:.5, color:"black", fontSize:{xs:12,sm:16,md:14}}}>{ejercicio.recuperacion}</Typography>
                </div>
                <div style={{display:"flex", marginTop:".5rem"}}>
                    <Typography sx={{width:"50%",display:"inline", background:"#DAD8D4", paddingY:.5, paddingX:1, color:"black", fontSize:{xs:12,sm:16,md:16}}}>No. JUGADORES</Typography>
                    <Typography sx={{width:"50%", display:"inline",paddingY:.5, paddingX:1, color:"black", fontSize:{xs:12,sm:16,md:16}}}>{ejercicio.no_jugadores}</Typography>
                </div>
                <div style={{display:"flex", marginTop:".5rem"}}>
                    <Typography sx={{width:"50%",display:"inline", background:"#DAD8D4", paddingY:.5, paddingX:1, color:"black", fontSize:{xs:12,sm:16,md:16}}}>TOQUES</Typography>
                    <Typography sx={{width:"50%", display:"inline",paddingY:.5, paddingX:1, color:"black", fontSize:{xs:12,sm:16,md:16}}}>{ejercicio.toques}</Typography>
                </div>
                <div style={{display:"flex", marginTop:".5rem"}}>
                    <Typography sx={{width:"50%",display:"inline", background:"#DAD8D4", paddingY:.5, paddingX:1, color:"black", fontSize:{xs:12,sm:16,md:16}}}>MATERIAL</Typography>
                    <Typography sx={{width:"50%", display:"inline",paddingY:.5, paddingX:1, color:"black", fontSize:{xs:12,sm:16,md:16}}}>{ejercicio.material}</Typography>
                </div>
                <div style={{display:"flex", marginTop:".5rem"}}>
                    <Typography sx={{width:"50%",display:"inline", background:"#DAD8D4", paddingY:.5, paddingX:1, color:"black", fontSize:{xs:12,sm:16,md:16}}}>MEDIDAS</Typography>
                    <Typography sx={{width:"50%", display:"inline",paddingY:.5, paddingX:1, color:"black", fontSize:{xs:12,sm:16,md:16}}}>{ejercicio.medidas}</Typography>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div style={{marginBottom:2}}>
                    <Typography sx={{display:"inline", background:"#DAD8D4", paddingY:.5, paddingX:1, color:"black", fontSize:{xs:12,sm:16,md:16}}}>DESGLOSE</Typography>
                </div>
                <Typography sx={{display:"inline",color:"black", fontSize:{xs:12,sm:16,md:16}}}>{ejercicio.desgloce}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <div style={{marginBottom:5}}>
                    <Typography sx={{display:"inline", background:"#DAD8D4", paddingY:.5, paddingX:1, color:"black", fontSize:{xs:12,sm:16,md:16}}}>EJERCICIO</Typography>
                    <Typography sx={{display:"inline",  paddingY:.5, paddingX:1, color:"black", fontSize:{xs:12,sm:16,md:16}}}>{ejercicio.ejercicio}</Typography>
                </div>
                    <Typography sx={{display:"inline",  paddingY:.5, paddingX:1, color:"red", fontSize:{xs:12,sm:16,md:16}}}>OBJETIVO</Typography>
                    <Typography sx={{display:"inline",  paddingY:.5, paddingX:1, color:"black", fontSize:{xs:12,sm:16,md:16}}}>{ejercicio.objetivo}</Typography>
            </Grid> 
        </Grid>
    </Box>
    <Box 
        sx={{
            marginBottom:2
        }}
        >
            <Box
            sx={{
                display:"flex",
                direction:"row"
            }}>
            <Typography sx={{width:"100%", textAlign:"center" ,background:"red", paddingY:1, paddingX:2, color:"white", fontSize:{xs:12,sm:16,md:28}}}>IMAGEN</Typography>
            </Box>
            {
                user.nivel == 6 &&
                <Box
                sx={{
                    diplay:"flex",
                    justifyContent:"center",
                    width:"100%",
                    padding:2
                }}>
                <Button sx={{margin:"auto"}} color="success" variant="contained" component="label">
                        Subir Imagen (PNG)
                    <input  hidden onClick={hanleOnClickInputImage} onChange={(e)=>handleUploadImage(e)} accept=".png"  type="file" />
                </Button>
                </Box>

            }

    </Box>
    <Box sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <Box
        component={"img"}
        src={`https://necaxa-analisis.s3.us-west-1.amazonaws.com/basicas/ejercicios-png/${ejercicio.clave}.png`}
        sx={{
            width:"70%"
        }}/>
    </Box>
    </Box>

  )
}

export default EjercicioPage