import React from 'react'

import {
  Typography, 
  Box,
  Stack,
  Paper,
  Divider

} from '@mui/material'

const slides =[13,14,15,16,17,18,19,20,21,22,23,24,25,26]


function Modelo() {
  return (
    <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
        marginY:{xs:3,sm:3, md:12},
        marginX:"auto",
        width:"100%"}}>
      <Box width={240} sx={{display:{xs:"none",sm:"none", md:"block"},}}></Box>
      
      <Box
      sx={{
        maxWidth:"90%"
      }}
      >
        <Box 
    sx={{
        marginBottom:2
    }}
    >
        <Box
        sx={{
            display:"flex",
            direction:"row"
        }}>
        <Typography sx={{width:"100%", textAlign:"center" ,background:"red", paddingY:1, paddingX:2, color:"white", fontSize:{xs:20,sm:20,md:28}}}>Modelo de entrenamiento</Typography>
        </Box>

</Box>
      <Stack
      direction={{xs:"column", md:"row"}}
      divider={<Box orientation="vertical"/>}
      spacing={{xs:1,md:12}}
      >
      <ul style={{marginLeft:3}}>
        <li style={{marginTop:"2rem"}}>
          <Typography  fontSize={28} variant='h4' color="primary">Método Técnico</Typography>
          <ul>
            <li style={{marginLeft:"2rem"}}><Typography  fontSize={22} variant='h5'>Recepción y golpeo</Typography>
            <ul>
              <li style={{marginLeft:"2rem"}}><Typography>Rebotadero</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Definición</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Golpeo de precisión</Typography></li>
            </ul>
            </li>
            <li style={{marginLeft:"2rem"}}><Typography  fontSize={22} variant='h5'>Habilidad y destreza</Typography></li>
            <li style={{marginLeft:"2rem"}}><Typography  fontSize={22} variant='h5'>Juero Aéreo</Typography>
            <ul>
              <li style={{marginLeft:"2rem"}}><Typography>Horca</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Individual</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Remate</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Despeje</Typography></li>
            </ul>
            </li>
          </ul>
        </li>
        <li style={{marginTop:"1rem"}}>
          <Typography fontSize={28} variant='h4' color="primary">Método Específico</Typography>
          <ul>
            <li style={{marginLeft:"2rem"}}><Typography  fontSize={22} variant='h5'>Fundamentos defensivos y ofensivos</Typography>
            <ul>
              <li style={{marginLeft:"2rem"}}><Typography>Perfiles</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Fildeos</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Rompimiento de Pared</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Desmarques</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Anticipos</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Carreras Perfiladas</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Cierres</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Terminación de jugada en ataque</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Recuperación orientada</Typography></li>
            </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul style={{marginLeft:3}}>
        <li style={{marginTop:"2rem"}}>
          <Typography fontSize={28} variant='h4' color="primary">Método Orientado</Typography>
          <ul>
            <li style={{marginLeft:"2rem"}}><Typography  fontSize={22} variant='h5'>Fundamentos Técnico-Tácticos</Typography>
            <ul>
              <li style={{marginLeft:"2rem"}}><Typography>Progresiones</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Transiciones</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Salidas</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Presión tras pérdida</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Recorridos</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Recuperaciones altas, medias y bajas</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Repliegues</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Enfrentamientos</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Fildeos</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Rompimiento de Pared</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Perfiles</Typography></li>
            </ul>
            </li>
          </ul>
        </li>
        <li style={{marginTop:"1rem"}}>
          <Typography fontSize={28} variant='h4' color="primary">Método Competitivo</Typography>
          <ul>
            <li style={{marginLeft:"2rem"}}>
            <ul>
              <li style={{marginLeft:"2rem"}}><Typography>Interescruadras</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Amplitud</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Profundidad</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Juego directo</Typography></li>
              <li style={{marginLeft:"2rem"}}><Typography>Superioridad e inferioridad numérica</Typography></li>
            </ul>
            </li>
          </ul>
        </li>
      </ul>
      </Stack>
      <Box>
      {
        slides.map((slide)=>{
          return(
            <Box key={slide}>
                <Box
                width={"100%"}
                marginTop={2}
                component="img"
                src={`https://necaxa-analisis.s3.us-west-1.amazonaws.com/basicas/slides/Slide${slide}.png`}
                >
                </Box>
            </Box>

          )
        })
      }
      </Box>

      </Box>

    </Stack>
  )
}

export default Modelo