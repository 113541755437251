import React, { useState } from 'react'
import {  Outlet } from 'react-router-dom';
import SideBar from '../../../components/SideBar/SideBar'
import {
  List,
  Typography,
  Divider,
  Box,
  Stack
} from '@mui/material'
import MultiSelectList from '../../../features/MultiSelectList/MultiSelectList';
import AnalisisRivalTorneo from './AnalisisRivalTorneo';



function AnalisisRival() {
  const [navValue, setNavValue] = useState("/analisis/propio");


  // choose a tournament
  const [temporada,setTemporada] = useState("2022-2023");
  const [torneo,setTorneo] = useState("Clausura");

  return (
    <Box
    sx={{
      maxWidth:"100vw"
    }}
    >
      <SideBar>
      {/* Lista de Por Torneo */}
      <List         
       sx={{
            marginBottom:8

        }}>
          <Typography variant={"overline"} fontSize={18} sx={{color:"red"}}>Por Torneo</Typography>
          <Divider />
          <MultiSelectList targetList={temporadasList} value={temporada} setValue={setTemporada} label="Temporada"/>
          <MultiSelectList targetList={torneosList} value={torneo} setValue={setTorneo} label="Torneo"/> 
      </List>
      </SideBar>

      {/* Box for mobile devices */}
            <Box
      marginTop={10}
      background="black"
      marginX={"auto"}
      width={"90%"}
      display={{
        xs:"block",
        sm: "block",
        md:"block",
        lg:"none",
        xl:"none"
      }}>
            {/* Navigation for mobile phones */}
            {
              navValue === "/analisis/propio" &&
            <Stack
              direction="row"
              sx={{
                display:{xs:"flex", sm:"flex", md:"flex",lg:"none"},
                marginTop:1,
                justifyContent:"space-around"
              }}
              >
             <MultiSelectList targetList={temporadasList} value={temporada} setValue={setTemporada} label="Temporada"/>
             <MultiSelectList targetList={torneosList} value={torneo} setValue={setTorneo} label="Torneo"/> 
            </Stack>
            }
      </Box>
      {
        navValue==="/analisis/propio"?
        <AnalisisRivalTorneo temporada={temporada} torneo={torneo}/>
        :
        <Outlet/>
      }
      
    </Box>
  )
}

export default AnalisisRival;

const temporadasList = ["2021-2022","2022-2023"]
const torneosList = ["Apertura", "Clausura"]