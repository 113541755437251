import React, { useState, useEffect } from 'react'
import SideBar from '../../../components/SideBar/SideBar'
import { Outlet, useNavigate } from 'react-router-dom'
import MultiSelectList from '../../../features/MultiSelectList/MultiSelectList';
import { AuthContext } from '../../../context/auth/AuthProvider';
import { useContext } from 'react';
import MultiSelectPlayers from '../../../features/MultiSelectPlayers/MultiSelectPlayers';
import { Stack } from '@mui/material';
import Loader from '../../../components/Loader/Loader'
import {Box} from '@mui/material';



function Jugadores() {
  const {token, user} = useContext(AuthContext)
  const navigate = useNavigate();

  const categoriasList = ["Primer equipo Femenil","Sub-18 Femenil", "Sub-23", "Sub-18", "Tercera división","Sub-16","Sub-15","Sub-14","Sub-13","Sub-12"]
  const [categoria, setCategoria] = useState("Primer equipo Femenil");
  const [jugadoresList, setjugadoresList] = useState()
  const [jugadorId, setJugadorId] = useState();
  const [loader, setLoader] = useState(true);
  // Hacer una petición para obtener los jugadores de esas categorías
  useEffect(()=>{
    const fetchJugadores = async()=>{
      const requestsOptions = {
        method: "GET",
        headers:{
          Authorization:"Bearer "+token
        }
      }

      const response = await fetch(encodeURI(`${process.env.REACT_APP_API_URL}/players?categoria=${categoria}`), requestsOptions)
      if(response.ok){
        const data = await response.json()
        setjugadoresList(data)
      }else{

      }
    }

    fetchJugadores();
  },[token, categoria])

  // cambiar el link en el que estamos dependiendo del id del jugador
  useEffect(()=>{
    if(jugadorId != undefined){
      navigate(`${jugadorId}`)
    }

  },[jugadorId])


  return (
    <div>
      { categoriasList && categoria &&  jugadoresList ?
        <div>
        {/* Navigation for desktop */}
        <SideBar >
           <Stack>
           <MultiSelectList targetList={categoriasList} value={categoria} setValue={setCategoria} label="Categoría"/>
           <MultiSelectPlayers targetList={jugadoresList} value={jugadorId} setValue={setJugadorId} label="Jugador"/>
           </Stack>
        </SideBar>
        {/* Navigation for mobile phones */}
        <Stack
           direction="row"
           sx={{
             display:{xs:"flex", sm:"flex", md:"flex", lg:"none"},
             marginTop:10,
             justifyContent:"space-around"
           }}
           >
           <MultiSelectList targetList={categoriasList} value={categoria} setValue={setCategoria} label="Categoría"/>
           <MultiSelectPlayers targetList={jugadoresList} value={jugadorId} setValue={setJugadorId} label="Jugador"/>
        </Stack>
      <Outlet />
        </div>
        : 
        
        <Box
        sx={{
         marginTop:10,
         width:"100%",
         height:"70vh"
        }}>
            <Loader show={loader}></Loader>
        </Box>
      }
    </div>
  )
}

export default Jugadores;