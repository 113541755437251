import React, {useState} from 'react'
import SideBar from '../../components/SideBar/SideBar'
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Divider,
  Box,
  InputLabel,
  MenuItem,
  ListSubheader,
  FormControl,
  Select
}
from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {Outlet} from 'react-router-dom'

function Metodologia() {
  const navigate = useNavigate()
  const [navValue, setNavValue] = useState("");
  const proyectoItems = [
    {
      text:"Misión",
      path:"/basicas/metodologia/mision",

    },
    {
      text:"Visión",
      path:"/basicas/metodologia/vision",

    },
    {
      text:"Filosofía",
      path:"/basicas/metodologia/filosofia",

    },
    {
      text:"Modelo de Entrenamiento",
      path:"/basicas/metodologia/modelo",

    }]

  const ejerciciosItems = [
      {
        text:"Método Técnico",
        path:"/basicas/metodologia/ejercicios/tecnico",
  
      },
      {
        text:"Método específico",
        path:"/basicas/metodologia/ejercicios/especifico",
  
      },
      {
        text:"Método orientado",
        path:"/basicas/metodologia/ejercicios/orientado",
  
      },
      {
        text:"Método competitivo",
        path:"/basicas/metodologia/ejercicios/competitivo",
  
      }]

  const handleChangeNavMenu = (event)=>{
    navigate(event.target.value)
    setNavValue(event.target.value)
  }
  return (
    <div>
      <SideBar>
      <List         
       sx={{
            marginBottom:8

        }}>
          <Typography variant={"overline"} fontSize={20} sx={{color:"red"}}>Proyecto</Typography>
          <Divider />
          {proyectoItems.map(({text, path})=>(
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
               <ListItemButton
                  sx={{
                      justifyContent:  'initial',
                      px: 2.5,
                      py:0
                    }}
                    onClick={()=>navigate(path)}
              >
              <ListItemText  primaryTypographyProps={{fontSize:"medium"}} primary={text}/>
              </ListItemButton>
            </ListItem>
          )
          )}
        </List>
        
      {/* Lista de Métodos */}
      <List         sx={{
          marginY:"auto",
        }}>
          <Typography variant={"overline"} fontSize={20} sx={{color:"red"}}>Ejercicios</Typography>
          <Divider />
          {ejerciciosItems.map(({text, path})=>(
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
               <ListItemButton
                  sx={{

                      justifyContent:  'initial',
                      px: 2.5,
                      py:0
                    }}
                    onClick={()=>navigate(path)}
              >
              <ListItemText  primaryTypographyProps={{fontSize:"medium"}} primary={text}/>
              </ListItemButton>
            </ListItem>
          )
          )}
      </List>
      </SideBar>
      {/* Box for mobile devices */}
      <Box
      marginTop={10}
      background="black"
      marginX={"auto"}
      width={"90%"}
      display={{
        xs:"block",
        sm: "block",
        md:"block",
        lg:"none",
        xl:"none"

      }}>
      <FormControl fullWidth sx={{ marginY: 1, minWidth: 320, marginX:"auto" }}>
        <InputLabel htmlFor="grouped-select">Navegación</InputLabel>
        <Select onChange={event =>handleChangeNavMenu(event)} value={navValue} id="grouped-select" label="Grouping">
          <ListSubheader sx={{color:"red"}}>Proyecto</ListSubheader>
          <Divider />
          {proyectoItems.map(({text, path})=>(
            <MenuItem key={text} value={path}>{text}</MenuItem>
          )
          )}
          <ListSubheader  sx={{color:"red"}}>Ejercicios</ListSubheader>
          <Divider />
          <MenuItem value={"/basicas/metodologia/ejercicios/tecnico"}>Método técnico</MenuItem>
          <MenuItem value={"/basicas/metodologia/ejercicios/especifico"}>Método específico</MenuItem>
          <MenuItem value={"/basicas/metodologia/ejercicios/orientado"}>Método orientado</MenuItem>
          <MenuItem value={"/basicas/metodologia/ejercicios/competitivo"}>Método competitivo</MenuItem>
        </Select>
      </FormControl>
      </Box>
      <Outlet/>
    </div>
  )
}

export default Metodologia = React.memo(Metodologia)