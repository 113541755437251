import React, {useContext, useState, useEffect} from 'react'
import {
    Typography, 
    Box,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Paper,
    Grid
  
  } from '@mui/material'
import EjercicioCard from './EjercicioCard';
import {AuthContext}from '../../../context/auth/AuthProvider'
import EjercicioPage from './EjercicioPage';
import Loader from '../../../components/Loader/Loader';


function Orientado() {
  const {token, user} = useContext(AuthContext)
  const [ejercicios, setEjercicios] = useState();

  // state to changue view
  const [ejercicioOpen, setEjercicioOpen] = useState(false);
  

  // useEffect to fetch the ejercicios
  useEffect(()=>{
    const fetchEjercicios = async()=>{
      const requestOptions = {
        method:"GET",
        headers:{
          "Content-type":"application/json",
          Authorization: "Bearer " + token
        }
      }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/ejercicios?metodo=orientado`, requestOptions)
      if(response.ok){
        const data = await response.json()
        setEjercicios(data);
      }
    }

    fetchEjercicios();
  },[])

  return (
    <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
        marginY:{xs:1, sm:1, md:1,lg:10,xl:10},
        width:"100%"}}>
      <Box width={300} sx={{backGround:"black" ,display:{xs:"none",sm:"none", md:"block"},}}></Box>
      
      <Box 
        sx={{
            width:"100%",
            marginX:{md:0,xl:8,lg:8},
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center"

        }}
      >
            <Typography variant="h3" sx={{textAlign:"center", }}>Método Orientado</Typography>
          
{           
            !ejercicioOpen &&

           
            //{/* Grid de ejercicios */}
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{marginY:3}}>
            {/* render habilidad */}
            {
                ejercicios  ?
                ejercicios.map(ejercicio=>(
                       <Grid key={ejercicio.clave} item xs={12} sm={6} md={4} lg={3}>
                            <EjercicioCard setEjercicioOpen={setEjercicioOpen} ejercicio={ejercicio}/>
                       </Grid>
                ))
                :
                <Loader show={true}/>
            }
      
            </Grid>}


      {
        ejercicioOpen && 
        <EjercicioPage ejercicio={ejercicioOpen} setEjercicioOpen={setEjercicioOpen} />
      }
    </Box>
    </Stack>
  )
}   

export default Orientado
