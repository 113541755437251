import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  registerables
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function LineChartBasicas({tests, variableSelected}) {
    // options to make the line chart
    const options = {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: '',
              },
            },
    };

    const labels = []
    const data_points = []
    const data = {
            labels,
            datasets: [
              {
                label: variableSelected,
                data: data_points,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              }
            ]
    };

  tests.forEach((test)=>{
    labels.push(test.fecha_test)
    data_points.push(test[variableSelected])

  })

  return (
     <Line options={options} data={data} />  
  )
}

export default React.memo(LineChartBasicas)