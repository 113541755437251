import React from 'react'
import { Outlet } from 'react-router-dom'
import {Stack} from '@mui/material'

function Basicas() {
  return (
    <Stack>
      <Outlet/>
    </Stack>
  )
}

export default Basicas