import { 
  Box, 
  Typography,
  Paper,
  Stack,
  Divider,
} from '@mui/material';
import React, { useContext } from 'react'
import {AuthContext} from '../../context/auth/AuthProvider'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import Loader from '../../components/Loader/Loader';

function Perfil() {
  const {user} = useContext(AuthContext);
  return (
    <Box
    sx={{
      marginTop:8
    }}
    >
    <Stack
    sx={{
      width:{xs:"100%",sm:"100%",md:"90%"},
      marginX:"auto",
      justifyContent:"center",
      alignItems:"center"
    }}>

    <AccountCircleIcon 
    color={"disabled"}
    sx={{
      fontSize:200

    }}/>    
    {
      user ?
      <Paper
    sx={{
      marginTop:2,
      padding:2,
      width:{xs:"90%",sm:"90%", md:"40%"}
    }}
    >
      <Stack
        display={"flex"}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginY={2}
        >
      <Typography>Email</Typography> <Typography sx={{color:"gray"}}>{user?.email}</Typography> 
      </Stack>
      <Divider></Divider>
      <Stack
        display={"flex"}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginY={2}
        >
      <Typography>Nombre</Typography> <Typography sx={{color:"gray"}}>{user?.nombre}</Typography> 
      
      </Stack>
      <Divider></Divider>
      <Stack
        display={"flex"}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginY={2}
        >
      <Typography>Departamento</Typography> <Typography sx={{color:"gray"}}>{user?.departamento}</Typography> 
      
      </Stack>
      <Divider></Divider>
      <Stack
        display={"flex"}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginY={2}
        >
      <Typography>Puesto</Typography> <Typography sx={{color:"gray"}}>{user?.puesto}</Typography> 
      
      </Stack>
    </Paper>
      :
      <Loader show={true} />  
  }

    </Stack>
    </Box>

  )
}

export default Perfil