import React from 'react'
import { Box, Typography } from '@mui/material'
import Default from '../../assets/images/players/default.jpeg'
import { useNavigate } from 'react-router-dom'


function PlayerCardButton({jugador}) {
  const navigate = useNavigate();

  const handleClick = (event)=>{
    const playerID = event.target.getAttribute("data-playerid")
    navigate(`/jugador/${playerID}`)
  }
  
  
  return (
    <Box
    component="button"
    onClick={(event)=>handleClick(event)}
    sx={buttonStyle}
    data-playerid={jugador.id}
    key={jugador.id}
    >
          <Box
            key={jugador.id}
            data-playerid={jugador.id}
            component="img"
            sx={{
              height: {xs:100,sm:150,lg:233,xl:233},
              width: "auto",
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 },
            }}
            alt={jugador.nombre}
            src={`https://necaxa-analisis.s3.us-west-1.amazonaws.com/images/jugadores/${jugador.id}.jpg`}
          />
    </Box>

  )
}

export default PlayerCardButton;

const buttonStyle ={
  cursor:"pointer",
  outline:"none",
  border:"none",
  boxShadow:"3px 3px 10px gray, -3px -3px 10px gray",
  '&:hover':{
    transform:"scale(1.055)"
  },
}