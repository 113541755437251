import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography, Divider } from '@mui/material';


function AccordionTests() {
  return (
    <Box maxWidth={1650}>
         {/* Psicología */}
         <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Piscología</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                    <Typography variant="overline" display="inline" color="text.secondary" fontSize={15}>Psicólogos:</Typography> <br />
                    <ul style={{margin:"0 1rem 0 1rem"}}>
                        <li><Typography fontSize={16} variant="subtitle1"display="inline">Javier Ibañez Briseño</Typography></li>
                        <li><Typography fontSize={16} variant="subtitle1"display="inline">Juan Eduardo Villanueva Canchola</Typography></li>
                    </ul>
                   
                    <Divider sx={{ marginX:0, marginBottom:3}}></Divider> 
                    <Typography fontSize={16} variant="h6"display="inline">Parámetros a evaluar:</Typography><br />
                    <ol style={{margin:"0 1rem 0 1rem"}}>
                        <li><Typography sx={{marginTop:1}} >Conocimiento</Typography>
                            <ol type="a" style={{margin:"0 1rem 0 1rem"}}>
                                <li>
                                <Typography variant="subtitle2" display="inline" >Enfoque y claridad:</Typography> 
                                <Typography variant="subtitle2"display="inline" color="text.secondary" >Qué tan enfocado está en su proceso y la claridad que tiene sobre su actividad deportiva</Typography>
                                </li>
                            </ol>
                        </li>
                        <li><Typography sx={{marginTop:1}}>Cognitivo</Typography>
                            <ol type="a" style={{margin:"0 1rem 0 1rem"}}>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Concentración y atención:</Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Siempre está atento cuando se dan las indicaciones.</Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Toma de decisiones:</Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Las decisiones que toma son adecuadas al nivel de presión al que se siente sometido.</Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Retención de la información: </Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Qué tan atento escucha las instrucciones y qué tan exactas las lleva a cabo.</Typography>
                                </li>
                            </ol>
                        </li>
                        <li><Typography sx={{marginTop:1}}>Emocional</Typography>
                            <ol type="a" style={{margin:"0 1rem 0 1rem"}}>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Gestión emocional:</Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Cómo maneja los estados emocionales dentro y fuera de la cancha.</Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Motivación:</Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Hace las actividades con gusto o se le llama constantemente la atención.</Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Autoconfianza:  </Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Realiza las actividades con confianza y sin dudas.</Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Tolerancia a la frustración:  </Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Tiene la capacidad de sobreponerse a los errores.</Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Expresión de sentimientos: </Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Tiene la capacidad de comunicar su estado emocional, enojo, tristeza, alegría.</Typography>
                                </li>
                            </ol>
                        </li>
                        <li><Typography sx={{marginTop:1}}>Conductual</Typography>
                            <ol type="a" style={{margin:"0 1rem 0 1rem"}}>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Disciplina:</Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Respeta las reglas internas del equipo, del club y del juego.</Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Actitud:</Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Positiva, negativa con compañeros, entrenadores y en sus actividades.</Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Resiliencia:  </Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Se sobrepone a los errores y a  las derrotas, emergiendo con más energía.</Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Comunicación:  </Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Mantiene buena comunicación con compañeros y cuerpo técnico en entrenamientos y partidos.</Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Liderazgo: </Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Personalidad fuerte y respetuosa que transmite confianza y seguridad a sus compañeros.</Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Activación: </Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Es energico o apático en sus actividades en entrenamientos y partidos.</Typography>
                                </li>
                            </ol>
                        </li>
                        <li><Typography sx={{marginTop:1}}>Adaptativo</Typography>
                            <ol type="a" style={{margin:"0 1rem 0 1rem"}}>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Coehsión:</Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Qué tanto está integrado al equipo, se siente parte importante del grupo.</Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Sentimiento de pertenencia:</Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Se identifica con el grupo,  y aporta para que el crecimiento sea mayor.</Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Identidad al Club:  </Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Se enorgullese de portar la camiseta del club y da lo mejor de sí en su representación.</Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Variables interpersonales:   </Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Extrovertido, introvertido, responsable, irresponsable, amable, indiferente, respetuoso, irrespetuoso.</Typography>
                                </li>
                            </ol>
                        </li>
                        

                    </ol>




                </AccordionDetails>
                  </Accordion>

        {/* Preparación Fíisica */}
        <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Preparación Física</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography variant="overline" display="inline" color="text.secondary" fontSize={15}>Jefe de Preparación Física:</Typography> <br />
                    <ul style={{margin:"0 1rem 0 1rem"}}>
                        <li><Typography fontSize={16} variant="subtitle1"display="inline">Juan Martín Lozano González</Typography></li>
                    </ul>
                   
                    <Divider sx={{ marginX:0, marginBottom:3}}></Divider> 
                    <Typography fontSize={16} variant="h6"display="inline">Parámetros a evaluar:</Typography><br />
                    
                    <ol style={{margin:"0 1rem 0 1rem"}}>
                        <li><Typography sx={{marginTop:1}} >Pruebas de fuerza</Typography>
                            <ol type="a" style={{margin:"0 1rem 0 1rem"}}>
                                <li>
                                <Typography variant="subtitle2" display="inline" >Jump Test:</Typography> 
                                <Typography variant="subtitle2"display="inline" color="text.secondary" >Objetivo: fuerza explosiva de aceleración, capacidad de acelerar y re-acelerar; predictivo de jugadores con buena cantidad de fibras explosivas.</Typography>
                                </li>
                                <li>
                                <Typography variant="subtitle2" display="inline" >Cross Over Test (Salto cruzado a 1 pie):</Typography> 
                                <Typography variant="subtitle2"display="inline" color="text.secondary" >Objetivo: Fuerza explosiva reactiva, capacidad de aplicar fuerza en el menor tiempo, y aplicación de esa fuerza en los cambios de dirección, predictivo de asimetrías y posible riesgo de lesión de rodillas.</Typography>
                                </li>
                            </ol>
                        </li>
                        <li><Typography sx={{marginTop:1}}>Pruebas de velocidad, agilidad y coordinación</Typography>
                            <ol type="a" style={{margin:"0 1rem 0 1rem"}}>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >20 metros salida detenida:</Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Objetivo: capacidad de aceleración y velocidad máxima del jugador, relación directa con el jump test.</Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Illinois (Prueba de agilidad y coordinación): </Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Objetivo: medir la coordinación y capacidad de cambiar de dirección y mantener un esfuerzo de alta intensidad y complejidad durante un tiempo prolongado. Relación directa con el salto Cross Over.</Typography>
                                </li>
                            </ol>
                        </li>
                        <li><Typography sx={{marginTop:1}}>Pruebas de resistencia aeróbica</Typography>
                            <ol type="a" style={{margin:"0 1rem 0 1rem"}}>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >1000 MTS:</Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Objetivo: Valorar la capacidad de tolerar la acidez y respuesta cardiorespiratoria del jugador, potencia aeróbica.</Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2" display="inline" >Vo2max:</Typography> 
                                    <Typography variant="subtitle2"display="inline" color="text.secondary" >Objetivo: identificar jugadores con buena capacidad de recuperación durante el partido, entre partidos y entrenamientos.</Typography>
                                </li>
                            </ol>
                        </li>

                    </ol>
                    </AccordionDetails>
        </Accordion>
        {/* Porteros */}
        <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Porteros</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography variant="overline" display="inline" color="text.secondary" fontSize={15}>Jefe del área:</Typography> <br />
                    <ul style={{margin:"0 1rem 0 1rem"}}>
                        <li><Typography fontSize={16} variant="subtitle1"display="inline">Ángel Francisco Maldonado Gastélum</Typography></li>
                    </ul>
                   
                    <Divider sx={{ marginX:0, marginBottom:3}}></Divider> 
                    <Typography fontSize={16} variant="h6"display="inline">Parámetros a evaluar:</Typography><br />
                    
                    <ol style={{margin:"0 1rem 0 1rem"}}>
                                <li>
                                <Typography variant="subtitle2" display="inline" >Salto vertical:</Typography> 
                                <Typography variant="subtitle2"display="inline" color="text.secondary" >Evaluamos la potencia en despegue.</Typography>
                                </li>
                                <li>
                                <Typography variant="subtitle2" display="inline" >Salto con rebote:</Typography> 
                                <Typography variant="subtitle2"display="inline" color="text.secondary" >Evaluación de potencia contra resistencia explosiva</Typography>
                                </li>
                                <li>
                                <Typography variant="subtitle2" display="inline" >Bloqueos laterales y frontales:</Typography> 
                                <Typography variant="subtitle2"display="inline" color="text.secondary" >Resultado de técnica sujeción</Typography>
                                </li>
                                <li>
                                <Typography variant="subtitle2" display="inline" >Despejes de meta y volea: </Typography> 
                                <Typography variant="subtitle2"display="inline" color="text.secondary" >Evaluación de técnica de golpeo</Typography>
                                </li>
                                <li>
                                <Typography variant="subtitle2" display="inline" >Prueba de coordinación y potencia:</Typography> 
                                <Typography variant="subtitle2"display="inline" color="text.secondary" >Resultado de velocidad más fuerza/coordinativa</Typography>
                                </li>

                    </ol>
                    </AccordionDetails>
        </Accordion>
        {/* Técnico */}
        <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Técnico</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography variant="overline" display="inline" color="text.secondary" fontSize={15}>Responsable de las evaluaciones técnicas:</Typography> <br />
                    <ul style={{margin:"0 1rem 0 1rem"}}>
                        <li><Typography fontSize={16} variant="subtitle1"display="inline">Luis Alberto Padilla</Typography></li>
                    </ul>
                   
                    <Divider sx={{ marginX:0, marginBottom:3}}></Divider> 
                    <Typography fontSize={16} variant="h6"display="inline">Parámetros a evaluar:</Typography><br />
                    
                    <ol style={{margin:"0 1rem 0 1rem"}}>
                                <li>
                                <Typography variant="subtitle2" display="inline" >Juego aéreo: </Typography> 
                                <Typography variant="subtitle2"display="inline" color="text.secondary" >Descripción: Dentro de un espacio marcado de 2x2 metros, colocado a 1 metro de distancia e la pared, el jugador deberá de realizar la mayor cantidad de golpeos de cabeza a la pared sin salirse del área señalada. Se contabiliza cada contacto en la pared del frontón.</Typography>
                                </li>
                                <li>
                                <Typography variant="subtitle2" display="inline" >Habilidad y destreza:</Typography> 
                                <Typography variant="subtitle2"display="inline" color="text.secondary" >Descripción: Se coloca una línea recta de 10 conos con un cono hacia un costado. El jugador deberá de realizar una conducción en zigzag con un solo íe, saiendo en el 5to espacio hacia el cono del costado, realizando una conducción en 8, parar, volver a entrar a la línea de conos y terminar en conducción lo más rápido posible. Si tira un cono deberá de volver a realizar la conducción.</Typography>
                                </li>
                                <li>
                                <Typography variant="subtitle2" display="inline" >Control y golpeo: </Typography> 
                                <Typography variant="subtitle2"display="inline" color="text.secondary" >Dentro del espacio marcado de 2x2 metros y colocado 2 metros de la pared del fontrón, el jugador deberá de golpear la pelota hacia la pared, teniendo que botar la pelota dentro del espacio señalado, pudiendo botar una sola vez, controlar con el pie derecho y golpear con el mismo pie de aire. Si el jugador o la pélota sae del espacio se termina la prueba.</Typography>
                                </li>
                    </ol>
                    </AccordionDetails>
        </Accordion>


    </Box>
  )
}

export default AccordionTests