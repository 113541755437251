import React from 'react'
import {
  Typography, 
  Box,
  Stack

} from '@mui/material'


function PerfilBasicas() {
  return (
    <div>PerfilBasicas</div>
  )
}

export default PerfilBasicas