import React, { useState } from 'react'
import { useNavigate, Outlet } from 'react-router-dom';
import SideBar from '../../../components/SideBar/SideBar'
import {
  List,
  Typography,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  MenuItem,
} from '@mui/material'




function AnalisisScouting() {
  const navigate = useNavigate()
  const [navValue, setNavValue] = useState("");
  const scoutingEntrenadores = [
    {
      text:"Presentaciones",
      path:"/analisis/scouting/presentaciones-entrenadores",
    },
    {
      text:"Análisis",
      path:"/analisis/scouting/analisis-entrenadores",
    },
    {
      text:"Trabajos de Investigación",
      path:"/analisis/scouting/investigacion-entrenadores",
    }]

  const scoutingJugadores = [
      {
        text:"Fichas",
        path:"/analisis/scouting/fichas-jugadores",
  
      },
      {
        text:"Jugadores",
        path:"/analisis/scouting/jugadores",
  
      },]

  const handleChangeNavMenu = (event)=>{
        navigate(event.target.value)
        setNavValue(event.target.value)
      }
  return (
    <Box
    sx={{
      maxWidth:"100vw"
    }}
    >
      <SideBar>
      {/* Lista de Directores Técnicos */}
      <List
       sx={{
            marginBottom:8

        }}>
          <Typography variant={"overline"} fontSize={18} sx={{color:"red"}}>Directores Técnicos</Typography>
          <Divider />
          {scoutingEntrenadores.map(({text, path})=>(
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
               <ListItemButton
                  sx={{
                      justifyContent:  'initial',
                      px: 2.5,
                      py:0
                    }}
                    onClick={()=>navigate(path)}
              >
              <ListItemText  primaryTypographyProps={{fontSize:"medium"}} primary={text}/>
              </ListItemButton>
            </ListItem>
          )
          )}
      </List>
      {/* Lista de Métodos */}
      <List         sx={{
          marginY:"auto",
        }}>
          <Typography variant={"overline"} fontSize={18} sx={{color:"red"}}>Jugadores</Typography>
          <Divider />
          {scoutingJugadores.map(({text, path})=>(
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
               <ListItemButton
                  sx={{

                      justifyContent:  'initial',
                      px: 2.5,
                      py:0
                    }}
                    onClick={()=>navigate(path)}
              >
              <ListItemText  primaryTypographyProps={{fontSize:"medium"}} primary={text}/>
              </ListItemButton>
            </ListItem>
          )
          )}
      </List>
      </SideBar>
      {/* Box for mobile devices */}
            <Box
      marginTop={10}
      background="black"
      marginX={"auto"}
      width={"90%"}
      display={{
        xs:"block",
        sm: "block",
        md:"block",
        lg:"none",
        xl:"none"

      }}>
      <FormControl fullWidth sx={{ marginY: 1, minWidth: 320, marginX:"auto" }}>
        <InputLabel htmlFor="grouped-select">Navegación</InputLabel>
        <Select onChange={event =>handleChangeNavMenu(event)} value={navValue} id="grouped-select" label="Grouping">
          <ListSubheader sx={{color:"red"}}>Directores Técnicos</ListSubheader>
          <Divider />
          {scoutingEntrenadores.map(({text, path})=>(
            <MenuItem key={text} value={path}>{text}</MenuItem>
          )
          )}
          <ListSubheader  sx={{color:"red"}}>Jugadores</ListSubheader>
          <Divider />
          {scoutingJugadores.map(({text, path})=>(
            <MenuItem key={text} value={path}>{text}</MenuItem>
          )
          )}
        </Select>
      </FormControl>
      </Box>
      <Outlet/>
    </Box>
  )
}

export default AnalisisScouting