import React,
{ useEffect,
  useState,
  useContext } from 'react'
import {
  Container,
  Grid,
  Box
} from '@mui/material'
import PlayerCardButton from '../../../features/PlayerCard/PlayerCardButton'
import { AuthContext } from '../../../context/auth/AuthProvider'
import { REACT_APP_API_URL } from '../../../services/API/API'
import Loader from '../../../components/Loader/Loader'


function HomeJugadores() {
  const [jugadores, setJugadores] = useState();
  const {token} = useContext(AuthContext);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    const fetchPlayers = async ()=>{
      const requestsOptions = {
        method: "GET",
        headers:{
          Authorization:"Bearer "+token
        }
      }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/players?categoria=Primer%20equipo`, requestsOptions)
      if(response.ok){
        const data = await response.json();
        setJugadores(data);
        setLoader(false);
      }
    }

    fetchPlayers();
   
  },[token])
  


  return (
    <Container
    maxWidth="xl"
    >
          <Grid
    container
    rowSpacing={3}
    columnSpacing={1}
    height="100%"
    sx={{
      justifyContent:{xs:"center", sm:"center"},
      marginX:"auto",
    }}
    >
      { 
      jugadores ?
              jugadores.map((jugador)=>(
                      <Grid key={jugador.id} item xs={4} sm={3} md={2} lg={1.5}>
                        <Box sx={{marginX:"auto"}}>
                          <PlayerCardButton jugador={jugador}/>
                        </Box>
                      </Grid>
                      
            ))
            :
            <Loader show={loader}></Loader>
            }
    </Grid>
    </Container>

  )
}

export default HomeJugadores
