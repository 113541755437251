import { Box } from '@mui/material'
import React from 'react'

function NotFund() {
  return (
    <Box
    sx={{
      width:"100vw",
      height:"100vh",
      background:"yellow"
    }}
    >
      Not fund
    </Box>
  )
}

export default NotFund