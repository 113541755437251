import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function MultiSelectList({targetList, label,setValue, value, sx, size}) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  sx = sx || false
  size = size || "normal"

  return (   
    <Box sx={sx ? sx:{ width:{xs:150,sm:250,  md:220} , marginY:2 }}>
      <FormControl fullWidth>
        <InputLabel size={size} id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={handleChange}
          size={size}
        >
            {
                targetList.map((option)=>(
                    <MenuItem  key={option} value={option}>{option}</MenuItem>
                ))

            }
        </Select>
      </FormControl>
    </Box>)
    
  };

