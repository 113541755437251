import React, {useState} from 'react'
import {
    Stack,
    Typography,
    Box,
    Grid,
    Divider
} from '@mui/material'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
import APPage from './APPage';

function AnalisisPropioTorneo({temporada, torneo}) {
  // state to changue view
  const [analisisOpen, setAnalisisOpen] = useState(false);
  const handleClickOnCard = (clave) =>{
   setAnalisisOpen(Torneos[temporada][torneo][clave])
  }

  const handleDownloadPDF = (clave)=>{
    // using Java Script method to get PDF file
    fetch(`https://necaxa-analisis.s3.us-west-1.amazonaws.com/analisis/propio/temporada/${temporada}/${torneo}/${clave}.pdf`).then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = `${clave}.pdf`;
            alink.click();
        })
    })
}

  return (
    <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
        marginY:{xs:1, sm:1, md:1,lg:10,xl:10},
        overflow:"hidden",
        maxWidth:"100%"}}>
      <Box width={300} sx={{backGround:"black" ,display:{xs:"none",sm:"none", md:"none",lg:"block"},}}></Box>
      
      <Box 
        sx={{
            width:"100%",
            marginX:{xs:0,md:0,xl:8,lg:8},
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center"

        }}
      >
    <Typography variant="h3" sx={{textAlign:"center", }}>Análisis Propio</Typography>
{   
    !analisisOpen &&
    <Box>
       <Box
    sx={{
      marginTop:10,
      width:"100%"
    }}>
    <Typography variant="h4" color="primary">Análisis De Rendimiento</Typography>
    <Divider></Divider>
    {/* Grid para los análisis de cada jornada */}
    <Grid container rowSpacing={2} columnSpacing={1} sx={{marginTop:5, marginX:"auto", width:"100%"}} minHeight={"100%"}>
        {   
            temporada ==="2023-2024" && torneo==="Clausura"?
            <h1>Aún No hay informes para este torneo</h1>
            :
            presentacionesRendimiento.map((presentacion)=>{
                return (
                  <Grid key={presentacion.clave}  item xs={12} sm={6} md={4}>
                    <Card sx={{ minWidth:300, maxWidth: 345, height:300}}  elevation={5}>
                      <CardActionArea  onClick={()=>handleDownloadPDF(presentacion.clave)}>
                        <CardMedia
                          component="img"
                          height="100"
                          image={`https://necaxa-analisis.s3.us-west-1.amazonaws.com/analisis/propio/temporada/${temporada}/${torneo}/${presentacion.clave}.png`}
                          alt={presentacion.clave}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {presentacion.name}
                          </Typography>
                          {/* <Typography variant="body2" color="text.secondary">
                            {presentacion.fecha}
                          </Typography> */}
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <Button size="small" color="primary" onClick={()=>handleClickOnCard(presentacion.clave)}>
                          Descargar Presentación
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                  );
            })
        }
    </Grid>
    </Box>
    <Box
    sx={{
      marginTop:10,
      width:"100%"
    }}>
    <Typography variant="h4" color="primary">Análisis Por Jornada</Typography>
    <Divider></Divider>
    {/* Grid para los análisis de cada jornada */}
    <Grid container rowSpacing={2} columnSpacing={1} sx={{marginTop:5, marginX:"auto", paddingBottom:4, width:"100%", minWidth:{xs:100, sm:100,md:200, lg:1000}}} minHeight={"70vh"}>
        {
           temporada ==="2023-2024" && torneo==="Apertura"?
           <h1>Aún No hay informes para este torneo</h1>
           :
            Object.keys(Torneos[temporada][torneo]).map((jornada)=>(
              <Grid key={Torneos[temporada][torneo][jornada].clave} item xs={12} sm={6} md={4}>
                     <Card sx={{ minWidth:300, maxWidth: 345, height:355}}  elevation={5}>
                       <CardActionArea  onClick={()=>handleClickOnCard(Torneos[temporada][torneo][jornada].clave)}>
                         <CardMedia
                           component="img"
                           height="100"
                           image={`https://necaxa-analisis.s3.us-west-1.amazonaws.com/analisis/propio/temporada/2022-2023/Apertura/${Torneos[temporada][torneo][jornada].clave}.png`}
                           alt={Torneos[temporada][torneo][jornada].clave}
                         />
                         <CardContent sx={{height:"100%"}}>
                           <Typography gutterBottom variant="h5" component="div">
                             Rival: {Torneos[temporada][torneo][jornada].Rival}
                           </Typography>
                           <Typography variant="body1" color="text.secondary">
                             Condición: {Torneos[temporada][torneo][jornada].Lugar}
                           </Typography>
                           <Typography variant="body2" color="text.secondary">
                             Marcador: {Torneos[temporada][torneo][jornada].Marcador}
                           </Typography>
                         </CardContent>
                       </CardActionArea>
                     </Card>
              </Grid>
            )) 
        }
    </Grid>
    </Box>
    </Box>
     }
    {
      analisisOpen &&
      <APPage temporada={temporada} torneo={torneo} setAnalisisOpen={setAnalisisOpen} jornada={analisisOpen}/>
    }

    </Box>


    </Stack>
  )
}

export default React.memo(AnalisisPropioTorneo);


const presentacionesRendimiento = [
    {
        name: "Mitad del Torneo",
        fecha:"01/01/2022",
        descripción:"Entregada por Jaime Lozano.",
        clave:"PA-Mid"
    },
    {
        name: "Final del Torneo",
        fecha:"01/01/2022",
        descripción:"Entregada por Gustavo Poyet.",
        clave:"PA-Final"
    },

]


const Torneos = {
  "2021-2022":{
    "Apertura":{
      "J-1":{
        Jornada:1,
        Rival:"Santos",
        Lugar:"Local",
        Marcador:"0-3",
        clave:"J-1"
      },
      "J-2":{
        Jornada:2,
        Rival:"América",
        Lugar:"Visitante",
        Marcador:"2-1",
        clave:"J-2"
      },
      "J-3":{
        Jornada:3,
        Rival:"Cruz Azul",
        Lugar:"Local",
        Marcador:"1-2",
        clave:"J-3"
      },
      "J-4":{
        Jornada:4,
        Rival:"San Luis",
        Lugar:"Visitante",
        Marcador:"0-2",
        clave:"J-4"
      },
      "J-5":{
        Jornada:5,
        Rival:"Pumas",
        Lugar:"Local",
        Marcador:"3-0",
        clave:"J-5"
      },
      "J-6":{
        Jornada:6,
        Rival:"Juárez",
        Lugar:"Local",
        Marcador:"1-0",
        clave:"J-6"
      },
      "J-7":{
        Jornada:7,
        Rival:"Chivas",
        Lugar:"Visitante",
        Marcador:"2-1",
        clave:"J-7"
      },
      "J-8":{
        Jornada:8,
        Rival:"Querétaro",
        Lugar:"Visitante",
        Marcador:"3-0",
        clave:"J-8"
      },
      "J-9":{
        Jornada:9,
        Rival:"Atlas",
        Lugar:"Local",
        Marcador:"0-3",
        clave:"J-9"
      },
      "J-10":{
        Jornada:10,
        Rival:"Pachuca",
        Lugar:"Visitante",
        Marcador:"1-0",
        clave:"J-10"
      },
      "J-11":{
        Jornada:11,
        Rival:"Tijuana",
        Lugar:"Local",
        Marcador:"3-0",
        clave:"J-11"
      },
      "J-12":{
        Jornada:12,
        Rival:"Tigres",
        Lugar:"Visitante",
        Marcador:"0-0",
        clave:"J-12"
      },
      "J-13":{
        Jornada:13,
        Rival:"Puebla",
        Lugar:"Local",
        Marcador:"0-1",
        clave:"J-13"
      },
      "J-14":{
        Jornada:14,
        Rival:"Toluca",
        Lugar:"Visitante",
        Marcador:"1-1",
        clave:"J-14"
      },
      "J-15":{
        Jornada:15,
        Rival:"Monterrey",
        Lugar:"Visitante",
        Marcador:"0-1",
        clave:"J-15"
      },
      "J-16":{
        Jornada:16,
        Rival:"Mazatlán",
        Lugar:"Local",
        Marcador:"2-1",
        clave:"J-16"
      },
      "J-17":{
        Jornada:17,
        Rival:"León",
        Lugar:"Visitante",
        Marcador:"3-0",
        clave:"J-17"
      },

    },
    "Clausura":{
      "J-1":{
        Jornada:1,
        Rival:"Juárez",
        Lugar:"Visitante",
        Marcador:"2-1",
        clave:"J-1"
      },
      "J-2":{
        Jornada:2,
        Rival:"Monterrey",
        Lugar:"Local",
        Marcador:"0-4",
        clave:"J-2"
      },
      "J-3":{
        Jornada:3,
        Rival:"Santos",
        Lugar:"Visitante",
        Marcador:"1-4",
        clave:"J-3"
      },
      "J-4":{
        Jornada:4,
        Rival:"Pachuca",
        Lugar:"Local",
        Marcador:"1-3",
        clave:"J-4"
      },
      "J-5":{
        Jornada:5,
        Rival:"Cruz Azul",
        Lugar:"Visitante",
        Marcador:"1-2",
        clave:"J-5"
      },
      "J-6":{
        Jornada:6,
        Rival:"Tijuana",
        Lugar:"Visitante",
        Marcador:"1-1",
        clave:"J-6"
      },
      "J-7":{
        Jornada:7,
        Rival:"León",
        Lugar:"Local",
        Marcador:"0-1",
        clave:"J-7"
      },
      "J-8":{
        Jornada:8,
        Rival:"Mazatlán",
        Lugar:"Visitante",
        Marcador:"0-0",
        clave:"J-8"
      },
      "J-9":{
        Jornada:9,
        Rival:"Toluca",
        Lugar:"Local",
        Marcador:"0-1",
        clave:"J-9"
      },
      "J-10":{
        Jornada:10,
        Rival:"Querétaro",
        Lugar:"Local",
        Marcador:"1-0",
        clave:"J-10"
      },
      "J-11":{
        Jornada:11,
        Rival:"Pumas",
        Lugar:"Visitante",
        Marcador:"1-3",
        clave:"J-11"
      },
      "J-12":{
        Jornada:12,
        Rival:"América",
        Lugar:"Local",
        Marcador:"0-1",
        clave:"J-12"
      },
      "J-13":{
        Jornada:13,
        Rival:"Atlas",
        Lugar:"Visitante",
        Marcador:"2-1",
        clave:"J-13"
      },
      "J-14":{
        Jornada:14,
        Rival:"San Luis",
        Lugar:"Local",
        Marcador:"4-2",
        clave:"J-14"
      },
      "J-15":{
        Jornada:15,
        Rival:"Tigres",
        Lugar:"Local",
        Marcador:"2-0",
        clave:"J-15"
      },
      "J-16":{
        Jornada:16,
        Rival:"Puebla",
        Lugar:"Visitante",
        Marcador:"0-1",
        clave:"J-16"
      },
      "J-17":{
        Jornada:17,
        Rival:"Chivas",
        Lugar:"Local",
        Marcador:"0-1",
        clave:"J-17"
      },
      "RC":{
        Jornada:"Reclasificación",
        Rival:"Cruz Azul",
        Lugar:"Visitante",
        Marcador:"1-1",
        clave:"RC"
      },

    }
  },
  "2022-2023":{
    "Apertura":{
      "J-1":{
        Jornada:1,
        Rival:"Toluca",
        Lugar:"Local",
        Marcador:"1-3",
        clave:"J-1"
      },
      "J-2":{
        Jornada:2,
        Rival:"Querétaro",
        Lugar:"Visitante",
        Marcador:"1-2",
        clave:"J-2"
      },
      "J-3":{
        Jornada:3,
        Rival:"Pumas",
        Lugar:"Visitante",
        Marcador:"1-0",
        clave:"J-3"
      },
      "J-4":{
        Jornada:4,
        Rival:"Juárez",
        Lugar:"Local",
        Marcador:"1-0",
        clave:"J-4"
      },
      "J-5":{
        Jornada:5,
        Rival:"Pachuca",
        Lugar:"Local",
        Marcador:"2-0",
        clave:"J-5"
      },
      "J-6":{
        Jornada:6,
        Rival:"Cruz Azul",
        Lugar:"Visitante",
        Marcador:"1-0",
        clave:"J-6"
      },
      "J-7":{
        Jornada:7,
        Rival:"San Luis",
        Lugar:"Visitante",
        Marcador:"1-2",
        clave:"J-7"
      },
      "J-8":{
        Jornada:8,
        Rival:"Monterrey",
        Lugar:"Local",
        Marcador:"1-2",
        clave:"J-8"
      },
      "J-9":{
        Jornada:9,
        Rival:"Puebla",
        Lugar:"Visitante",
        Marcador:"2-2",
        clave:"J-9"
      },
      "J-10":{
        Jornada:10,
        Rival:"Chivas",
        Lugar:"Local",
        Marcador:"0-4",
        clave:"J-10"
      },
      "J-11":{
        Jornada:11,
        Rival:"Tigres",
        Lugar:"Visitante",
        Marcador:"0-0",
        clave:"J-11"
      },
      "J-12":{
        Jornada:12,
        Rival:"León",
        Lugar:"Local",
        Marcador:"3-2",
        clave:"J-12"
      },
      "J-13":{
        Jornada:13,
        Rival:"Santos",
        Lugar:"Visitante",
        Marcador:"3-1",
        clave:"J-13"
      },
      "J-14":{
        Jornada:14,
        Rival:"América",
        Lugar:"Local",
        Marcador:"1-2",
        clave:"J-14"
      },
      "J-15":{
        Jornada:15,
        Rival:"Tijuana",
        Lugar:"Visitante",
        Marcador:"1-1",
        clave:"J-15"
      },
      "J-16":{
        Jornada:16,
        Rival:"Mazatlán",
        Lugar:"Local",
        Marcador:"2-2",
        clave:"J-16"
      },
      "J-17":{
        Jornada:17,
        Rival:"Atlas",
        Lugar:"Visitante",
        Marcador:"1-0",
        clave:"J-17"
      },
      "RC":{
        Jornada:"Reclasificación",
        Rival:"Tiges",
        Lugar:"Visitante",
        Marcador:"2-0",
        clave:"RC"
      },

    },
    "Clausura":{
      "J-1":{
        Jornada:1,
        Rival:"A. San Luis",
        Lugar:"Local",
        Marcador:"2-3",
        clave:"J-1"
      },
      "J-2":{
        Jornada:2,
        Rival:"León",
        Lugar:"Visitante",
        Marcador:"2-1",
        clave:"J-2"
      },
      "J-3":{
        Jornada:3,
        Rival:"Cruz Azul",
        Lugar:"Local",
        Marcador:"1-0",
        clave:"J-3"
      },
      "J-4":{
        Jornada:4,
        Rival:"Pachuca",
        Lugar:"Visitante",
        Marcador:"2-1",
        clave:"J-4"
      },
      "J-5":{
        Jornada:5,
        Rival:"Tijuana",
        Lugar:"Local",
        Marcador:"1-1",
        clave:"J-5"
      },
      "J-6":{
        Jornada:6,
        Rival:"América",
        Lugar:"Visitante",
        Marcador:"2-1",
        clave:"J-6"
      },
      "J-7":{
        Jornada:7,
        Rival:"Pumas",
        Lugar:"Local",
        Marcador:"3-1",
        clave:"J-7"
      },
      "J-8":{
        Jornada:8,
        Rival:"Monterrey",
        Lugar:"Visitante",
        Marcador:"2-1",
        clave:"J-8"
      },
      "J-9":{
        Jornada:9,
        Rival:"Querétaro",
        Lugar:"Local",
        Marcador:"1-1",
        clave:"J-9"
      },
      "J-10":{
        Jornada:10,
        Rival:"Tigres",
        Lugar:"Local",
        Marcador:"0-1",
        clave:"J-10"
      },
      "J-11":{
        Jornada:11,
        Rival:"Juárez FC",
        Lugar:"Visitante",
        Marcador:"1-1",
        clave:"J-11"
      },
      "J-12":{
        Jornada:12,
        Rival:"Mazatlán FC",
        Lugar:"Visitante",
        Marcador:"0-1",
        clave:"J-12"
      },
      "J-13":{
        Jornada:13,
        Rival:"Santos",
        Lugar:"Local",
        Marcador:"0-0",
        clave:"J-13"
      },
      "J-14":{
        Jornada:14,
        Rival:"Chivas",
        Lugar:"Visitante",
        Marcador:"1-0",
        clave:"J-14"
      },
      "J-15":{
        Jornada:15,
        Rival:"Puebla",
        Lugar:"Local",
        Marcador:"1-1",
        clave:"J-15"
      },
      "J-16":{
        Jornada:16,
        Rival:"Atlas",
        Lugar:"Local",
        Marcador:"1-3",
        clave:"J-16"
      }
      
    }
  }


}



