import React, { useState, useEffect } from 'react'
import {
    Typography,
    Divider,
    Box,
    List,
    Stack,
    Slider
} from '@mui/material'
import SideBar from '../../../../components/SideBar/SideBar'
import MultiSelectList from '../../../../features/MultiSelectList/MultiSelectList'
import { useOpta } from '../../../../context/opta/OptaProvider'
import { useAuth } from '../../../../context/auth/AuthProvider'
import ScatterChart from '../../../../components/Charts/ScatterChart'
import Loader from '../../../../components/Loader/Loader'

function ExplorarJugadores() {
    const { columns } = useOpta();
    const { token } = useAuth();
    const [tournament, setTournament] = useState("Apertura 2022");
    const [xMetric, setXMetric] = useState("Goles/90");
    const [yMetric, setYMetric] = useState("Toques/90");
    const [minMinutos, setMinMinutos] = useState(0) 
    const [data, setData] = useState();

    // useEffect to fetch player data
    useEffect(()=>{
        const fetchData = async ()=>{
            const requestsOptions = {
            method:"GET",
            headers:{
                "Content-Type":"application/json",
                Authorization: "Bearer " +token
            }
            }
            const response = await fetch(`${process.env.REACT_APP_API_URL}/opta/players/scatter/?tournament_id=ei37yy9qltzgkbd3c15hq1d04&metric_x=${xMetric}&metric_y=${yMetric}&min_minutos=${minMinutos}`,requestsOptions);
            if (response.ok){
            const data = await response.json()
            setData(data);
            }else{
            console.log("Error Fetching Data in Explorar Jugadores Page")
            }}
    
        fetchData();   
    },[xMetric, yMetric, minMinutos])

    
  return (
    <Box
    sx={{
      maxWidth:"100vw"
    }}
    >
      <SideBar>
      <List         
       sx={{
            marginBottom:1

        }}>
          <Typography variant={"overline"} fontSize={18} sx={{color:"red"}}>Elegir Torneo</Typography>
          <Divider />
          <MultiSelectList targetList={Object.keys(tournaments)} value={tournament} setValue={setTournament} label="Torneo"/>
      </List>
      <List         
       sx={{
            marginBottom:1

        }}>
          <Typography variant={"overline"} fontSize={18} sx={{color:"red"}}>Elegir Métricas</Typography>
          <Divider />
          {
            columns.length>0 &&
            <div>
                <MultiSelectList targetList={columns} value={xMetric} setValue={setXMetric} label="Eje X"/>
                <MultiSelectList targetList={columns} value={yMetric} setValue={setYMetric} label="Eje Y"/>
            </div>

          }
      </List>
      <List         
       sx={{
            marginBottom:1

        }}>
          <Typography variant={"overline"} fontSize={18} sx={{color:"red"}}>Filtros</Typography>
          <Divider sx={{ marginBottom:3}}/>
          <Typography variant={"overline"} fontSize={12} sx={{color:"black"}}>Minutos Jugados: {minMinutos}</Typography>
          <Box 
          sx={{
            maxWidth:"90%",
            marginX:"auto"
          }}>
            <Slider
              aria-label="Temperature"
              defaultValue={0}
              getAriaValueText={()=>minMinutos}
              valueLabelDisplay="auto"
              color="secondary"
              onChangeCommitted={(event, value)=>{setMinMinutos(value)}}
              step={1}
              marks
              min={0}
              max={2000}
            />
          </Box>

      </List>
      </SideBar>


      {/* Box for mobile devices */}
    <Box
      marginTop={10}
      background="black"
      marginX={"auto"}
      width={"90%"}
      display={{
        xs:"block",
        sm: "block",
        md:"block",
        lg:"none",
        xl:"none"
      }}>
            {/* Navigation for mobile phones */}
            <Stack
              direction="row"
              sx={{
                display:{xs:"flex", sm:"flex", md:"flex", lg:"none"},
                marginTop:1,
                justifyContent:"space-around"
              }}
              >
             <MultiSelectList sx={{ width:"100%" , marginY:2 }} targetList={Object.keys(tournaments)} value={tournament} setValue={setTournament} label="Torneo"/>
            </Stack>
            <List sx={{marginBottom:1}}>
                <Typography variant={"overline"} fontSize={18} sx={{color:"red"}}>Elegir Métricas</Typography>
                <Divider />
                {
                    columns.length>0 &&
                    <div>
                        <MultiSelectList sx={{ width:"100%" , marginY:2 }} targetList={columns} value={xMetric} setValue={setXMetric} label="Eje X"/>
                        <MultiSelectList sx={{ width:"100%" , marginY:2 }} targetList={columns} value={yMetric} setValue={setYMetric} label="Eje Y"/>
                    </div>

                }
            </List>
            <List>
          <Typography variant={"overline"} fontSize={18} sx={{color:"red"}}>Filtros</Typography>
          <Divider sx={{ marginBottom:3}}/>
          <Typography variant={"overline"} fontSize={12} sx={{color:"black"}}>Minutos Jugados: {minMinutos}</Typography>
          <Box 
          sx={{
            maxWidth:"90%",
            marginX:"auto",
            marginBottom:5
          }}>
            <Slider
              aria-label="Temperature"
              defaultValue={0}
              getAriaValueText={()=>minMinutos}
              valueLabelDisplay="auto"
              color="secondary"
              onChangeCommitted={(event, value)=>{setMinMinutos(value)}}
              step={1}
              marks
              min={0}
              max={2000}
            />
          </Box>

      </List>
    </Box>
    <Box 
        sx={{
          maxWidth:"90%",
          marginLeft:{sm:0, md:0, lg:30},
          marginTop:{sm:0,md:10},
        }}>
    <Typography variant={"h3"} fontSize={{xs:30,md:50}} marginX={"auto"} textAlign={"center"}>{`${xMetric} vs ${yMetric}`}</Typography>
      {
        data ?

        <ScatterChart data={data} x_label={xMetric} y_label={yMetric}/>
        :
        <Loader show={true} />
      }
              </Box>
      
    </Box>
  )
}

export default ExplorarJugadores

const tournaments = {
    "Apertura 2022": "ei37yy9qltzgkbd3c15hq1d04",
}