import React, { useState } from 'react'
import { useNavigate, Outlet } from 'react-router-dom';
import SideBar from '../../../components/SideBar/SideBar'
import {
  List,
  Typography,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  MenuItem,
  Stack
} from '@mui/material'
import MultiSelectList from '../../../features/MultiSelectList/MultiSelectList';
import AnalisisPropioTorneo from './AnalisisPropioTorneo';



function AnalisisPropio() {
  const navigate = useNavigate()
  const [navValue, setNavValue] = useState("/analisis/propio");


  // choose a tournament
  const [temporada,setTemporada] = useState("2022-2023");
  const [torneo,setTorneo] = useState("Clausura");




  const handleChangeNavMenu = (event)=>{
        navigate(event.target.value)
        setNavValue(event.target.value)
      }
  return (
    <Box
    sx={{
      maxWidth:"100vw"
    }}
    >
      <SideBar>
      {/* Lista de Por Torneo */}
      <List
       sx={{
            marginBottom:8

        }}>
          <Typography variant={"overline"} fontSize={18} sx={{color:"red"}}>Por Torneo</Typography>
          <Divider />
          {porTorneo.map(({text, path})=>(
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
               <ListItemButton
                  sx={{

                      justifyContent:  'initial',
                      px: 2.5,
                      paddingBottom:2
                    }}
                    onClick={()=>{navigate(path);  setNavValue(path)}}
              >
              <ListItemText  primaryTypographyProps={{fontSize:"medium"}} primary={text}/>
              </ListItemButton>
            </ListItem>
          )
          )}
          <MultiSelectList targetList={temporadasList} value={temporada} setValue={setTemporada} label="Temporada"/>
          <MultiSelectList targetList={torneosList} value={torneo} setValue={setTorneo} label="Torneo"/> 
      </List>
      {/* Lista de Métodos */}
      <List         sx={{
          marginY:"auto",
        }}>
          <Typography variant={"overline"} fontSize={18} sx={{color:"red"}}>Proyectos</Typography>
          <Divider />
          {proyectos.map(({text, path})=>(
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
               <ListItemButton
                  sx={{

                      justifyContent:  'initial',
                      px: 2.5,
                      py:0
                    }}
                    onClick={()=>{navigate(path);  setNavValue(path)}}
              >
              <ListItemText  primaryTypographyProps={{fontSize:"medium"}} primary={text}/>
              </ListItemButton>
            </ListItem>
          )
          )}
      </List>
      </SideBar>

      {/* Box for mobile devices */}
            <Box
      marginTop={10}
      background="black"
      marginX={"auto"}
      width={"90%"}
      display={{
        xs:"block",
        sm: "block",
        md:"block",
        lg:"none",
        xl:"none"

      }}>
      <FormControl fullWidth sx={{ marginY: 1, minWidth: 320, marginX:"auto" }}>
        <InputLabel htmlFor="grouped-select">Navegación</InputLabel>
        <Select onChange={event =>handleChangeNavMenu(event)} value={navValue} id="grouped-select" label="Grouping">
          <ListSubheader sx={{color:"red"}}>Por Torneo</ListSubheader>
          <Divider />
          {porTorneo.map(({text, path})=>(
            <MenuItem key={text} value={path}>{text}</MenuItem>
          )
          )}
          <ListSubheader  sx={{color:"red"}}>Proyectos</ListSubheader>
          <Divider />
          {proyectos.map(({text, path})=>(
            <MenuItem key={text} value={path}>{text}</MenuItem>
          )
          )}
        </Select>
      </FormControl>
            {/* Navigation for mobile phones */}
            {
              navValue === "/analisis/propio" &&
            <Stack
              direction="row"
              sx={{
                display:{xs:"flex", sm:"flex", md:"flex",lg:"none"},
                marginTop:1,
                justifyContent:"space-around"
              }}
              >
             <MultiSelectList targetList={temporadasList} value={temporada} setValue={setTemporada} label="Temporada"/>
             <MultiSelectList targetList={torneosList} value={torneo} setValue={setTorneo} label="Torneo"/> 
            </Stack>
            }
      </Box>
      {
        navValue==="/analisis/propio"?
        <AnalisisPropioTorneo temporada={temporada} torneo={torneo}/>
        :
        <Outlet/>
      }

    </Box>
  )
}

export default AnalisisPropio;

const porTorneo = [
  {
    text:"Por Torneo",
    path:"/analisis/propio",
  }]

const proyectos = [
    {
      text:"Proyectos",
      path:"/analisis/propio/proyectos",

    },]


const temporadasList = ["2021-2022","2022-2023"]
const torneosList = ["Apertura", "Clausura"]