import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Container} from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import {Button} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../context/auth/AuthProvider';


const  appsMenuList = [
    {
      category:"Básicas",
      list:[
        {
          text:"Jugadores",
          icon: "",
          path:"/basicas/jugadores",
        },
        {
          text:"Metodología",
          icon: "",
          path:"/basicas/metodologia/ejercicios/tecnico",
        },
        {
          text:"Análisis Post-Partido",
          icon: "",
          path:"/basicas/analisis-post",
        },
        {
          text:"Formatos",
          icon: "",
          path:"/basicas/formatos",
        },
      ]
    },
    {
      category:"Visorías",
      list:[
        {
          text:"Jugadores",
          icon: "",
          path:"/visorias/jugadores",
        },
      ]
    },
    {
      category:"Análisis",
      list:[
        {
          text:"Análisis Propio",
          icon: "",
          path:"/analisis/propio",
        },
        {
          text:"Análisis del Rival",
          icon: "",
          path:"/analisis/rival",
        },
        {
          text:"Scouting",
          icon: "",
          path:"/analisis/scouting/jugadores",
        },
        {
          text:"Especiales",
          icon: "",
          path:"/analisis/especiales",
        },
        {
          text:"Explorar Jugadores",
          icon: "",
          path:"/analisis/scouting/explorar-jugadores",
        },
        {
          text:"Rankings",
          icon: "",
          path:"/analisis/scouting/ranking-jugadores",
        },
        {
          text:"Manuales",
          icon: "",
          path:"/analisis/Manuales",
        },
      ]
    }

]


export default function Navbar() {
  const {user} = useContext(AuthContext);
  const {handleLogout} = useContext(AuthContext);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElApps, setAnchorElApps] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isAppsMenuOpen = Boolean(anchorElApps);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setAnchorElApps(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleAppsMenuOpen = (event) => {
    setAnchorElApps(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{
        marginTop:7
      }}
    >
      <MenuItem onClick={()=>{navigate("/perfil"); handleMenuClose();}}>Perfil</MenuItem>
      <MenuItem onClick={handleLogout}>Cerrar Sesión</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={()=>{navigate("/perfil"); handleMenuClose();}}>Perfil</MenuItem>
      {user?.nivel==6 &&
      <MenuItem onClick={()=>{navigate("/admin"); handleMenuClose();}}>Admin</MenuItem>
      }
      <MenuItem onClick={handleLogout}>Cerrar Sesión</MenuItem>
    </Menu>
  );

  const AppsMenuID = 'primary-search-account-menu-apps';
  const renderAppsMenu = (
    <Menu 
      anchorEl={isAppsMenuOpen}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      id={AppsMenuID}
      keepMounted
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      open={isAppsMenuOpen}
      onClose={handleMenuClose}
    sx={{ marginTop:7}}>
      <MenuList
        sx={{
          width:400

        }}
      >
        {appsMenuList.map(({category, list},index)=>(
              <MenuList key={category}>
                    <MenuItem>
                      <Typography variant="body1" color="text.secondary">
                          {category}
                      </Typography>           
                    </MenuItem>
                  {list.map(({text, icon, path})=>(
                      <MenuItem key={text} onClick={()=>{navigate(path); handleMenuClose();}}>
                            <ListItemText sx={{marginLeft:5}}>{text}</ListItemText>
                      </MenuItem>

                  ))}
            { index == 2? null: (<Divider/>) }
              </MenuList>
              ))}
      </MenuList> 
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <Container maxWidth="xl" >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            sx={{ display: { xs: 'none', md: 'block' }, cursor:"pointer" }}
            onClick={()=>navigate("/home")}
          >
            ANÁLISIS NECAXA
          </Typography>

          <IconButton
            size="large"
            edge="start"
            aria-label="apps menu"
            aria-controls={AppsMenuID}
            aria-haspopup="true"
            onClick={handleAppsMenuOpen}
            color="inherit"
            sx={{ m:{xs:0,sm:0,md:"auto",lg:"auto"} }}
          >
            <AppsIcon fontSize='large'/>
          </IconButton>
          <Box
          src="https://necaxa-analisis.s3.us-west-1.amazonaws.com/images/equipos/1.png"
          component="img"
          display={{md:"none",lg:"none"}}
          sx={{
            width:45,
            height:"auto",
            margin:"auto"
          }}
          onClick={()=>{navigate("/home")}}
          >

          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
        </Container>
      </AppBar>
      {renderAppsMenu}
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
