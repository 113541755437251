import React from 'react'
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';


export default function Loader({show}) {
  return (
    <Stack sx={{ color: 'grey.500', 
    position: "absolute",
    left: 0,
    right: 0,
    margin: "0 auto",
    backgroundColor:"rgba(255, 255, 255, 1)",
    height:"100%",
    opacity:show ?1: 0,
    display:show ? "flex": "none",
    zIndex:1000,
    justifyContent:"center",
    alignItems:"center" }} spacing={2} direction="row">
      <CircularProgress color="primary" />
    </Stack>
  )
}
