import React,{useState} from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import {
    Box,
    Paper,

} from '@mui/material'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export default function RadarJugadores({player_data, player_comp}) {
    const [testData, setTestData] = useState();
    const filtered = Object.keys(player_data)
    .filter(key => labels.includes(key))
    .reduce((obj, key) => {
      obj[key] = player_data[key];
      return obj;
    }, {});



    let data_scores;
    if (filtered){
        data_scores={
            labels: Object.keys(filtered).map(l =>{
              if(l.length>10 && l.includes("/")){
                return l.split("/")
              }else{
                return l.split(" ")
              }
            }),
            datasets:[
                {
                    label: player_data.Nombre,
                    data:Object.values(filtered),
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                }
            ]
        }
    }
    if (player_comp){
      const filteredComp = Object.keys(player_comp)
      .filter(key => labels.includes(key))
      .reduce((obj, key) => {
        obj[key] = player_comp[key];
        return obj;
      }, {});
  
  
  
      data_scores={
        labels: Object.keys(filtered).map(l =>{
          if(l.length>10 && l.includes("/")){
            return l.split("/")
          }else{
            return l.split(" ")
          }
        }),
        datasets:[
            {
                label: player_data.Nombre,
                data:Object.values(filtered),
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
            {
              
              label:player_comp.Nombre,
              data:Object.values(filteredComp),
              backgroundColor: 'rgba(0, 251, 145, 0.2)',
              borderColor: 'rgba(0, 251, 145, 1)',
              borderWidth: 1,
            }

        ]}
    }

    const options = {
        scales:{
          r:{
            pointLabels:{
              callback:(pointLabel, index, labels)=>pointLabel,
              font: {
                  size: 8,
              }
          }
          }
        }
      }

    return (

        data_scores &&
           <Radar options={options} data={data_scores} />     )
;
}

const labels =[
    "Recuperaciones/90",
    "Pases/90",
    "Regates/90",
    "Regates exitosos, %",
    "Goles/90",
    "Duelos ganados, %",
    "Centros exitosos, %"
]
const labelsDict = {
    "Delantero":[
        "Recuperaciones/90",
        "Pases/90",
        "Regates/90",
        "Regates exitosos, %",
        "Goles/90",
        "Duelos ganados, %",
        "Centros exitosos, %"]
}