import React from 'react'
import ImagePlace from '../../../assets/images/Picture1.png'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';


function EjercicioCard({setEjercicioOpen, ejercicio}) {

  const handleClick = ()=>{
    setEjercicioOpen(ejercicio);
  }

  return (
    <Card key={ejercicio.clave} sx={{ marginX:"auto",maxWidth: 345, height:{x:600, sm:400,lg:320, xl:400} }}>
      <CardActionArea
      sx={{
        height:"100%"
      }}
      onClick={handleClick}
      >
        <CardMedia
          component="img"
          height="140"
          image={`https://necaxa-analisis.s3.us-west-1.amazonaws.com/basicas/ejercicios-png/${ejercicio.clave}.png`}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {ejercicio.clave}
          </Typography>
          <Typography variant="overline">Objetivo: </Typography>
          <Typography variant="body2" color="text.secondary">
            {ejercicio.objetivo}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default EjercicioCard