import React from 'react'
import {
  Typography,
  Box,
  Container,
  TextField,
  Button,
  Paper
} from '@mui/material';
import { useContext } from 'react';
import { AuthContext } from '../../context/auth/AuthProvider';
import { useState } from 'react';


function LogIn() {
  const {handleLogin} = useContext(AuthContext)
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);



  return (
    <Container
    maxWidth="sm"
    sx={{
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      height: '100vh'
    }}
    >
 
      <Paper elevation={10}
      sx={
       { paddingX:{lg:10,xs:4},
       paddingY:{lg:10,xs:8}
      }
      }
      >
      <Typography textAlign="left" variant="h4" element="h2">Iniciar sesión</Typography>
        <Box
        component="form"
        sx={{}}
        autoComplete="off"
        >

            <TextField onChange={(event)=>{setUsername(event.target.value)}} fullWidth margin="dense" type="email" id="input-mail" label="Email" variant="standard" />
            <TextField onChange={(event)=>{setPassword(event.target.value)}} fullWidth margin="dense" type="password" id="input-password" label="Password" variant="standard" />
            <Button  onClick={()=>{handleLogin(username, password)}} variant="contained" fullWidth sx={{marginTop:5}}>Iniciar sesión</Button>
        </Box>
      </Paper>

    </Container>
  )
}

export default LogIn