import React from 'react'
import {
  Typography, 
  Box,
  Stack,
  Paper

} from '@mui/material'


function Vision() {
  return (
    <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
        marginY:{xs:3,sm:3, md:12},
        marginX:"auto",
        maxWidth:"90%"}}>
      <Box width={240} sx={{display:{xs:"none",sm:"none", md:"block"},}}></Box>
      
      <Box>
      <Box 
        sx={{
            marginBottom:2
        }}
        >
            <Box
            sx={{
                display:"flex",
                direction:"row"
            }}>
            <Typography sx={{width:"100%", textAlign:"center" ,background:"red", paddingY:1, paddingX:2, color:"white", fontSize:{xs:20,sm:20,md:28}}}>Visión al 2023</Typography>
            </Box>

    </Box>

      <Paper elevation={1} sx={{ padding:3, maxWidth:800}}>
          <Typography fontSize={{xs:16, sm:16,md:24}} variant="subtitle1">
          Lograr en tres años posicionarnos como unos de los cinco mejores proyectos de Fuerzas Básicas del futbol mexicano; consolidando jugadores completamente desarrollados y altamente competitivos en el alto rendimiento, logrando colocar 11 jugadores de fuerzas básicas en el primer equipo.

          </Typography>
      </Paper>
      </Box>

    </Stack>
  )
}

export default Vision