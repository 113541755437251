import React from 'react'
import { 
  Stack,
  Paper,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import { useState } from 'react'
import HomeJugadores from './Jugadores/HomeJugadores'
import HomeEquipos from './Equipos/HomeEquipos';


function Home() {
  // state and function to toggle between playres and teams
  const [alignment, setAlignment] = useState('Jugadores');
  const handleChange = (event, newAlignment) => {
    if(newAlignment===alignment){
      
    }else{
      setAlignment(newAlignment);
    }
  };


  return (
    <Stack
    sx={{
      marginTop:8,
      color:"white",
      width:"100%",
      marginX:"auto",
      overflow:"hidden"
 
    }}
    >
      {/* Toggle to switch between players and teams */}
    <ToggleButtonGroup
      color="secondary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      sx={{
        marginY:3,
        marginX:{xs:"auto", sm:"auto",lg:2, xl:2}

      }}
    >
      <ToggleButton value="Jugadores" size="small">Jugadores</ToggleButton>
      <ToggleButton value="Equipos" size="small">Equipos</ToggleButton>
    </ToggleButtonGroup>
    
    {/* Container for players and teams */}
    <Paper 
    elevetaion={10}
    sx={{
      maxHeight:"100%",
      padding:{xs:0, sm:3},
    }}
    >

        {
            alignment === "Jugadores" ? 
            <HomeJugadores/>
            :
            <HomeEquipos/>
          }



    </Paper>
    </Stack>
  )
}

export default Home;

