import React,
{ useEffect,
  useState,
  useContext } from 'react'

import {
    Container,
    Grid
  } from '@mui/material'
import TeamCardButton from '../../../features/TeamCard/TeamCardButton'

import { AuthContext } from '../../../context/auth/AuthProvider'
import Loader from '../../../components/Loader/Loader'


function HomeEquipos() {
  const [equipos, setEquipos] = useState();
  const {token} = useContext(AuthContext);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    const fetchTeams = async ()=>{
      const requestsOptions = {
        method: "GET",
        headers:{
          Authorization:"Bearer "+token
        }
      }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/teams?liga=Liga%20MX`, requestsOptions)
      if(response.ok){
        const data = await response.json();

        setEquipos(data);
      }
    }

    fetchTeams();
   
  },[token])
  

  return (
    <Container
    maxWidth="xl"
    >
          <Grid
    container
    rowSpacing={3}
    columnSpacing={1}
    height="100%"
    sx={{
      justifyContent:{xs:"center", sm:"center",md:"center", lg:"flex-start"},
      marginX:"auto",
    }}
    >
      { 
      equipos ?
              equipos.map((equipo)=>(
                      <Grid key={equipo.id} item xs={4} sm={3} md={2} lg={1.5}>
                          <TeamCardButton equipo={equipo}/>
                      </Grid>
                      
            ))
            :
            <Loader show={loader}></Loader>
          }
    </Grid>
    </Container>

  )
}

export default HomeEquipos;