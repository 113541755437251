import React from 'react'
import { Box,  } from '@mui/material'
import { useNavigate } from 'react-router-dom'

function TeamCardButton({equipo}) {
    const navigate = useNavigate();
  
    const handleClick = (event)=>{
      const equipoID = event.target.getAttribute("data-equipoid")
      
      navigate(`/equipo/${equipoID}`)
    }
    
    
    return (
      <Box
      component="button"
      onClick={(event)=>handleClick(event)}
      sx={buttonStyle}
      data-equipoid={equipo.id}
      key={equipo.id}
      >
            <Box
              key={equipo.id}
              data-equipoid={equipo.id}
              component="img"
              sx={{
                height: {xs:75,sm:115,lg:100,xl:100},
                width: "auto",
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
              }}
              alt={equipo.nombre}
              src={`https://necaxa-analisis.s3.us-west-1.amazonaws.com/images/equipos/${equipo.id}.png`}
            />
      </Box>
  
    )
  }
  
  export default TeamCardButton;
  
  const buttonStyle ={
    cursor:"pointer",
    outline:"none",
    border:"none",
    boxShadow:"3px 3px 10px gray, -3px -3px 10px gray",
    '&:hover':{
      transform:"scale(1.055)"
    },
    padding:1
  }