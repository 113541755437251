import React from 'react'
import {
  Typography, 
  Box,
  Stack,
  Paper

} from '@mui/material'


function Mision() {
  return (
    <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
        marginY:{xs:3,sm:3, md:12},
        marginX:"auto",
        maxWidth:"90%"}}>
      <Box width={240} sx={{display:{xs:"none",sm:"none", md:"block"},}}></Box>
      
      <Box>
      <Box 
        sx={{
            marginBottom:2
        }}
        >
            <Box
            sx={{
                display:"flex",
                direction:"row"
            }}>
            <Typography sx={{width:"100%", textAlign:"center" ,background:"red", paddingY:1, paddingX:2, color:"white", fontSize:{xs:20,sm:20,md:28}}}>Misión</Typography>
            </Box>

    </Box>

      <Paper elevation={1} sx={{ padding:3, maxWidth:800}}>
          <Typography fontSize={{xs:16, sm:16,md:24}} variant="subtitle1">
          Ser el espacio deportivo de alto rendimiento que dote a nuestros jugadores del óptimo desarrollo, físico, técnico, táctico y mental; que potencialicen sus capacidades de acuerdo a la edad, para nutrir al primer equipo de Club Necaxa, logrando así mismo, mejores seres humanos; capaces de asumir riesgos y enfrentar grandes retos,  colocando nuestros futbolistas en el mercado nacional o internacional, generando el retorno esperado de la inversión hecha.

          </Typography>
      </Paper>
      </Box>

    </Stack>
  )
}

export default Mision