import React, { createContext, useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { useAuth } from '../auth/AuthProvider';

const OptaContext = createContext();

function OptaProvider({children}) {
  const [columns, setColumns] =  useState({});
  const [columnsTeams, setColumnsTeams] = useState({});
  const {token} = useAuth();

  // useEffect to fetch player data
  useEffect(()=>{
      const fetchColumns = async ()=>{
        const requestsOptions = {
          method:"GET",
          headers:{
            "Content-Type":"application/json",
            Authorization: "Bearer " +token
          }
        }
        // i'm using a hard code value for player and tournament
        const response = await fetch(`${process.env.REACT_APP_API_URL}/opta/players/?player_id=73ffdzeslizj3vj7pv94071sl&tournament_id=ei37yy9qltzgkbd3c15hq1d04`,requestsOptions);
        if (response.ok){
          const data = await response.json()
          setColumns(Object.keys(data));
        }else{
          console.log("Error fetching the columns")
        }
  
  
      }
  
      fetchColumns();   

  },[token])
  
    // useEffect to fetch team data
    useEffect(()=>{
      const fetchColumnsTeams = async ()=>{
        const requestsOptions = {
          method:"GET",
          headers:{
            "Content-Type":"application/json",
            Authorization: "Bearer " +token
          }
        }
        // i'm using a hard code value for team and tournament
        const response = await fetch(`${process.env.REACT_APP_API_URL}/opta/teams/?team_id=b7hn6knkuxbpj1i8rmzapns7b&tournament_id=AP2022`,requestsOptions);
        if (response.ok){
          const data = await response.json()
          setColumnsTeams(Object.keys(data));
        }else{
          console.log("Error fetching the columns")
        }
  
  
      }
  
      fetchColumnsTeams();   

  },[token])

  const value = {
    columns,
    columnsTeams
  }

  return (
    <OptaContext.Provider value={value}>
        {children}
    </OptaContext.Provider>
  )
}

export default OptaProvider

export const useOpta = () => useContext(OptaContext);

const metricsToAvoid = []
