import React, { useMemo } from 'react';
import {Box} from '@mui/material'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import Loader from '../../components/Loader/Loader'


ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

function ScatterChart({data, x_label, y_label,team_opta_id}) {
    const options = {
      maintainAspectRatio: false,
      elements:{
        point:{
          radius:10
        }
      },
      plugins: {
        legend: {
          display: false
       },
        tooltip: {
          callbacks: {
            title: function(tooltipItem, data) {
              return (
`${tooltipItem[0]["raw"]["team_name"]}`)
            },
            beforeBody: function(tooltipItem, data) {
              return (
`${x_label}: ${tooltipItem[0]["raw"][x_label]} 
${y_label}: ${tooltipItem[0]["raw"][y_label]}`
              )

            },
          }
        }
      },
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: y_label,
              font:{
                size:30
              }
            }
            
          },
          x:{
            beginAtZero:true,
            title: {
              display: true,
              text: x_label,
                            font:{
                size:30
              }
            }
          }
        },
      };
      
    let colors = []
    let newData = data.map((item)=>{
        let x = item[x_label]
        let y = item[y_label]
        if(item["team_id"]===team_opta_id){
          colors.push("rgba(0, 99, 132, 1)")
        }else{
          colors.push('rgba(255, 99, 132, 1)')
        }
        return {x:x,y:y, ...item}
      })  

    const dataChart = {
        datasets: [
          {
            label: `${x_label} vs ${y_label}` ,
            data: newData,
            backgroundColor:colors,
          },
        ],
      };
      
  return (
    <Box sx={{height:"82vh"}}>
        {
                newData ?
                <Scatter options={options} data={dataChart} height={"100%"} width={"100%"}/>
                :
                <Loader show={true} />
        }
    </Box>

  )
}

export default ScatterChart
