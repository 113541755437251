import React, { useContext, useState, useEffect } from 'react'
import SideBar from '../../components/SideBar/SideBar'
import MultiSelectList from '../../features/MultiSelectList/MultiSelectList';
import {
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Stack, 
    Box,
    Typography,
    Grid,
    Button,
    CardActions,
    CardContent,
    CardMedia,
    CardActionArea,
    Card
} from '@mui/material'
import useAuth from '../../hooks/useAuth';

function AnalisisPost() {
    const { token } = useAuth();
    const [ categoria, setCategoria ] = useState("Sub-20");
    const [ torneo, setTorneo ] = useState("CL-2023");

    const torneos = ["CL-2023"]
    const categoriasList = ["Sub-20", "Sub-18"]
    const [ analisis, setAnalisis ] = useState([]);
    
    const handleClickOnCard = (jornada) =>{
        handleDownloadPDF(jornada);
      }
    
      const handleDownloadPDF = (jornada)=>{
        // using Java Script method to get PDF file
        fetch(`https://necaxa-analisis.s3.us-west-1.amazonaws.com/basicas/analisis-post/${torneo}/${categoria}/J-${jornada}.pdf`).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = `Análisis-${torneo}-${categoria}-${jornada}.pdf`;
                alink.click();
            })
        })
    }
    
    // useEffect to fetch analisis
    useEffect(() => {
      const fetchAnalisis = async()=>{
        const requestOptions = {
          method: "GET",
          headers:{
            "Content-Type":"application/json",
            Authorization: "Bearer " +token
          }
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/analisis-post?categoria=${categoria}&torneo=${torneo}`, requestOptions)
        if (response.ok){
          const data = await response.json()
          console.log(data)
          setAnalisis(data)
        }
      }
    
      fetchAnalisis()
    }, [categoria, torneo])
    


  return (
    <>
    <SideBar>
           {/* <MultiSelectList targetList={categoriasList} value={categoria} setValue={setCategoria} label="Categoría"/>
           <MultiSelectList targetList={torneos} value={torneo} setValue={setTorneo} label="Torneo"/> */}
            <div>
              <FormControl sx={{   width: {xs:270,sm:300,md:210}}} size="small">
                <InputLabel id="demo-select-small">Categoría</InputLabel>
                 <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      defaultValue={categoria}
                      value={categoria}
                      label="Categoría"
                      onChange={(event)=>{ setCategoria(event.target.value);}}>
                  {
                    categoriasList.map((categoria)=>{
                      return <MenuItem key={categoria} value={categoria}>{categoria}</MenuItem>
                    })

                  }

                  </Select>
              </FormControl>
        </div>
            <div style={{"marginTop":"1rem"}}>
              <FormControl  sx={{width: {xs:270,sm:300,md:210}}} size="small">
                <InputLabel id="demo-select-small">Torneo</InputLabel>
                 <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      defaultValue={torneo}
                      value={torneo}
                      label="Torneo"
                      onChange={(event)=>{ setTorneo(event.target.value);}}>
                  {
                    torneos.map((torneo)=>{
                      return <MenuItem key={torneo} value={torneo}>{torneo}</MenuItem>
                    })

                  }

                  </Select>
              </FormControl>
        </div>
    </SideBar>
     {/* Box for mobile devices */}
     <Box
     marginTop={10}
     background="black"
     marginX={"auto"}
     width={"90%"}
     display={{
       xs:"block",
       sm: "block",
       md:"block",
       lg:"none",
       xl:"none"
     }}>
        <Stack
              direction={{xs:"column",sm:"row"}}
              sx={{
                display:{xs:"flex", sm:"flex", md:"none"},
                marginTop:1,
                justifyContent:{xs: "center",sm:"space-around"},
                alignItems:"center"
              }} >

            <div>
              <FormControl sx={{   width: {xs:270,sm:300,md:210}}} size="small">
                <InputLabel id="demo-select-small">Categoría</InputLabel>
                 <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      defaultValue={categoria}
                      value={categoria}
                      label="Categoría"
                      onChange={(event)=>{ setCategoria(event.target.value);}}>
                  {
                    categoriasList.map((categoria)=>{
                      return <MenuItem key={categoria} value={categoria}>{categoria}</MenuItem>
                    })

                  }

                  </Select>
              </FormControl>
        </div>
            <div>
              <FormControl  sx={{width: {xs:270,sm:300,md:210}, marginTop: {xs:1, sm:0 } }} size="small">
                <InputLabel id="demo-select-small">Torneo</InputLabel>
                 <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      defaultValue={torneo}
                      value={torneo}
                      label="Torneo"
                      onChange={(event)=>{ setTorneo(event.target.value);}}>
                  {
                    torneos.map((torneo)=>{
                      return <MenuItem key={torneo} value={torneo}>{torneo}</MenuItem>
                    })

                  }

                  </Select>
              </FormControl>
        </div>
        </Stack>

   </Box>
   <Box
    sx={{
            maxWidth:"90%",
            marginLeft:{sm:0,md:30},
            marginTop:{sm:0,md:10},
            textAlign:"center"
          }}>
            <Typography variant='h2'>{categoria}</Typography>
            <Typography variant='h4'>Torneo: {torneo}</Typography>
            <Grid container spacing={3} sx={{marginTop:5, marginX:"auto"}} minHeight={"70vh"}>
            {
                analisis.map((analisis_ind)=>{
                    return (
                    <Grid key={analisis_ind.id} item xs={12} md={4}>
                        <Card sx={{ minWidth:300, maxWidth: 345, maxHeight:375}}  elevation={5}>
                        <CardActionArea  onClick={()=>handleClickOnCard(analisis_ind.jornada)}>
                            {/* <CardMedia
                            component="img"
                            height="100"
                            image={`https://necaxa-analisis.s3.us-west-1.amazonaws.com/basicas/analisis-post/J-${analisis_ind.jornada}.png`}
                            alt={analisis_ind.id}
                            /> */}
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Jornada {analisis_ind.jornada}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Rival: {analisis_ind.rival}
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="small" color="primary" onClick={()=>handleClickOnCard(analisis_ind.jornada)}>
                            Descargar Análisis
                            </Button>
                        </CardActions>
                        </Card>
                    </Grid>
                    );
                })
            }
            </Grid>
    </Box>
   </>
  )
}

export default AnalisisPost;

const jornadas = [
]