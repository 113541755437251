import React, {useContext, useState, useEffect} from 'react'
import {
    Typography, 
    Box,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Paper,
    Grid
  
  } from '@mui/material'
import EjercicioCard from './EjercicioCard';
import {AuthContext}from '../../../context/auth/AuthProvider'
import EjercicioPage from './EjercicioPage';
import Loader from '../../../components/Loader/Loader';


function Tecnico() {
  const {token, user} = useContext(AuthContext)
  const [ejercicios, setEjercicios] = useState();

  // state to changue view
  const [ejercicioOpen, setEjercicioOpen] = useState(false);
  
  // state and function to toggle between ejercicios
  const [alignment, setAlignment] = useState('habilidad');
  const handleChange = (event, newAlignment) => {
    setEjercicioOpen(false);
    if(newAlignment == null){
      setAlignment("habilidad");
    }else{
      setAlignment(newAlignment);
    }
  };

  // useEffect to fetch the ejercicios
  useEffect(()=>{
    const fetchEjercicios = async()=>{
      const requestOptions = {
        method:"GET",
        headers:{
          "Content-type":"application/json",
          Authorization: "Bearer " + token
        }
      }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/ejercicios?metodo=${alignment}`, requestOptions)
      if(response.ok){
        const data = await response.json()
        setEjercicios(data);
      }
    }

    fetchEjercicios();
  },[alignment])

  console.log()
  return (
    <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
        marginY:{xs:1, sm:1, md:1,lg:10,xl:10},
        width:"100%"}}>
      <Box width={300} sx={{backGround:"black" ,display:{xs:"none",sm:"none", lg:"block"},}}></Box>
      
      <Box 
        sx={{
            width:"100%",
            marginX:{md:0,xl:8,lg:8},
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center"

        }}
      >
            <Typography variant="h3" sx={{textAlign:"center", }}>Método Técnico</Typography>
            {/* Toggle to switch habilidad, tecnico, con definicion*/}
            <ToggleButtonGroup
            color="secondary"
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
            sx={{
                marginY:3,
                marginX:{xs:"auto", sm:"auto",lg:"auto", xl:"auto"}

            }}
            >
            <ToggleButton value="habilidad" size="small" sx={{width:"120px"}}>Habilidad</ToggleButton>
            <ToggleButton value="tecnico" size="small" sx={{width:"120px"}}>Técnico</ToggleButton>
            <ToggleButton value="tecnico-definicion" size="small">Con Definición</ToggleButton>
            </ToggleButtonGroup>
            
            {           
            !ejercicioOpen && 
            //{/* Grid de ejercicios */}
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent={"center"} alignItems={"center"}>
            {/* render habilidad */}
            {
                ejercicios && alignment === "habilidad" &&
                ejercicios.map(ejercicio=>(
                        <Grid key={ejercicio.clave} item xs={12} sm={6} md={4} lg={3}>
                            <EjercicioCard setEjercicioOpen={setEjercicioOpen} ejercicio={ejercicio}/>
                        </Grid>
                ))
            }
            {/* render tenico */}
            {
                ejercicios && alignment === "tecnico" &&
                ejercicios.map(ejercicio=>(
                  <Grid key={ejercicio.clave} item xs={12} sm={6} md={4} lg={3}>
                        <EjercicioCard setEjercicioOpen={setEjercicioOpen} ejercicio={ejercicio}/>
                  </Grid>
            ))
            }
            {/* render definicion */}
            {
                ejercicios && alignment === "tecnico-definicion" &&
                ejercicios.map(ejercicio=>(
                  <Grid key={ejercicio.clave} item xs={12} sm={6} md={4} lg={3}>
                        <EjercicioCard setEjercicioOpen={setEjercicioOpen} ejercicio={ejercicio}/>
                  </Grid>
            ))
            }

            </Grid>
            
            }


      {
        ejercicioOpen && 
        <EjercicioPage ejercicio={ejercicioOpen} setEjercicioOpen={setEjercicioOpen} />
      }
    </Box>
    </Stack>
  )
}   

export default Tecnico
