import './App.css';
import { Route, Routes } from 'react-router-dom';
import {ThemeProvider} from '@mui/material'
import theme from './context/theme/theme'

// import Providers
import AuthProvider from './context/auth/AuthProvider';
import OptaProvider from './context/opta/OptaProvider';

import Login from './pages/LogIn/LogIn'
import MainLayout from './Layouts/Main/MainLayout'
import Home from './pages/Home/Home'
import Basicas from './pages/Basicas/Basicas'
import NotFund from './pages/NotFund/NotFund'
import ProtectedRoute from './Routes/ProtectedRoute/ProtectedRoute';
import JugadoresBasicas from './pages/Basicas/JugadoresBasicas/Jugadores'
import JugadorBasicas from './pages/Basicas/JugadoresBasicas/JugadorBasicas'
import MetodologiaBasicas from './pages/Basicas/Metodologia'
import VisoriasBasicas from './pages/Basicas/Visorias'
import Perfil from './pages/Perfil/Perfil';
import Jugador from './pages/Jugador/Jugador'
import Equipo from './pages/Equipo/Equipo'

// Imports from Basicas
import Mision from './pages/Basicas/Metodologia/Mision'
import Vision from './pages/Basicas/Metodologia/Vision'
import Filosofia from './pages/Basicas/Metodologia/Filosofia'
import Modelo from './pages/Basicas/Metodologia/Modelo'
import PerfilBasicas from './pages/Basicas/Metodologia/PerfilBasicas'
import Tecnico from './pages/Basicas/Metodologia/Tecnico'
import Especifico from './pages/Basicas/Metodologia/Especifico'
import Orientado from './pages/Basicas/Metodologia/Orientado'
import Competitivo from './pages/Basicas/Metodologia/Competitivo'
import FormatosBasicas from './pages/Basicas/FormatosBasicas';
import AnalisisPost from './pages/Basicas/AnalisisPost';
import Unauthorized from './pages/Unauthorized/Unauthorized'
// Imports for Visorias
import Visorias from './pages/Visorias/Visorias'


// Imports from Análisis
import Analisis from './pages/Analisis/Analisis'
import AnalisisPropio from './pages/Analisis/AnalisisPropio/AnalisisPropio'
import AnalisisPropioProyectos from './pages/Analisis/AnalisisPropio/AnalisisPropioProyectos'
import AnalisisRival from './pages/Analisis/AnalisisRival/AnalisisRival'
import AnalisisDatos from './pages/Analisis/AnalisisDatos/AnalisisDatos'
import AnalisisEspeciales from './pages/Analisis/AnalisisEspeciales/AnalisisEspeciales'
import AnalisisManuales from './pages/Analisis/AnalisisManuales/AnalisisManuales'
import AnalisisScouting from './pages/Analisis/AnalisisScouting/AnalisisScouting'
import ExplorarJugadores from './pages/Analisis/AnalisisScouting/Jugadores/ExplorarJugadores';
import RankingJugadores from './pages/Analisis/AnalisisScouting/Jugadores/RankingJugadores';


// imports from  Análisis - Scouting
import ScoutingPresentaciones from './pages/Analisis/AnalisisScouting/Entrenadores/ScoutingPresentaciones';
import AnalisisEntrenadores from './pages/Analisis/AnalisisScouting/Entrenadores/AnalisisEntrenadores'
import InvestigacionesEntrenadores from './pages/Analisis/AnalisisScouting/Entrenadores/InvestigacionesEntrenadores'
import JugadoresFichas from './pages/Analisis/AnalisisScouting/Jugadores/JugadoresFichas'
import JugadoresScouting from './pages/Analisis/AnalisisScouting/Jugadores/JugadoresScouting'



function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <OptaProvider>
          <Routes>
            {/* Login Route */}
            <Route  index path="/login" element={<Login/>}></Route>
            {/* Layout for all the routes */}
            <Route element={<MainLayout/>}>
              {/* Default route */}
              <Route  element={   
              <ProtectedRoute nivelAuth={1} departmentsAuth={["*"]}>
                <Home/>
              </ProtectedRoute>
              }/>

              <Route path="/home"  element={
                    <ProtectedRoute nivelAuth={1} departmentsAuth={["*"]}>
                          <Home/>
                    </ProtectedRoute>
                    }> 
                    <Route path="jugadores"></Route>
                    <Route path="equipos"></Route>
              </Route>

              {/* Jugador Route */}
              <Route path="/jugador/:playerId" element={
                    <ProtectedRoute nivelAuth={3} departmentsAuth={["*"]}>
                      <Jugador />
                    </ProtectedRoute>
              }  />
                    
              {/* Equipo Route */}
              <Route path="/equipo/:equipoId" element={
                    <ProtectedRoute nivelAuth={3} departmentsAuth={["*"]}>
                      <Equipo />
                    </ProtectedRoute>
              }  />
              {/* Análisis Routes */}
              <Route path="/analisis"  element={
                    <ProtectedRoute nivelAuth={5} departmentsAuth={["*"]}>
                          <Analisis/>
                    </ProtectedRoute>
                    }> 
                    <Route path="propio" element={<AnalisisPropio/>}>
                      <Route path="proyectos" element={<AnalisisPropioProyectos/>} />
                    </Route>
                    <Route path="rival" element={<AnalisisRival/>}/>
                    <Route path="datos" element={<AnalisisDatos/>}/>
                    <Route path="especiales" element={<AnalisisEspeciales/>}/>
                    <Route path="manuales" element={<AnalisisManuales/>}/>
                    <Route path="scouting" element={<AnalisisScouting/>}>
                        <Route path="presentaciones-entrenadores" element={<ScoutingPresentaciones/>} />
                        <Route path="analisis-entrenadores" element={<AnalisisEntrenadores/>} />
                        <Route path="investigacion-entrenadores" element={<InvestigacionesEntrenadores/>} />
                        <Route path="fichas-jugadores" element={<JugadoresFichas/>} />
                        <Route path="jugadores" element={<JugadoresScouting/>} />
                        <Route path="explorar-jugadores" element={<ExplorarJugadores/>} />
                        <Route path="ranking-jugadores" element={<RankingJugadores/>} />
                    </Route>
              </Route>



              {/* Básicas Routes */}
              <Route path="/basicas"  element={
                        <ProtectedRoute nivelAuth={1} departmentsAuth={["Básicas"]}>
                              <Basicas/>
                        </ProtectedRoute>}>
                            <Route path="jugadores" element={<JugadoresBasicas/>}>
                                  <Route path=":jugadorId" element={<JugadorBasicas/>} />
                            </Route>
                            <Route path="metodologia" element={<MetodologiaBasicas/>}>
                                  <Route path='mision' element={<Mision/>} />
                                  <Route path='vision' element={<Vision/>} />
                                  <Route path='filosofia' element={<Filosofia/>} />
                                  <Route path='modelo' element={<Modelo/>} />
                                  <Route path='perfil' element={<PerfilBasicas/>} />
                                  <Route path='ejercicios'>
                                      <Route path='tecnico' element={<Tecnico/>} />
                                      <Route path='especifico' element={<Especifico/>} />
                                      <Route path='orientado' element={<Orientado/>} />
                                      <Route path='competitivo' element={<Competitivo/>} />
                                  </Route>
                            </Route>
                            <Route path="visorias" element={<VisoriasBasicas/>} ></Route>
                            <Route path="analisis-post" element={<AnalisisPost/>} ></Route>
                            <Route path="formatos" element={<FormatosBasicas/>} ></Route>
                            <Route path="*" element={<NotFund />} />
              </Route>


              {/* Profile Route */}
              <Route path="perfil" element={
                                          <ProtectedRoute nivelAuth={1} departmentsAuth={["*"]}>
                                              <Perfil />
                                          </ProtectedRoute>}>

              </Route>
              {/* Unauthorized route */}
              <Route path="unauthorized" element={
              <ProtectedRoute nivelAuth={1} departmentsAuth={["*"]}>
                <Unauthorized/>
              </ProtectedRoute>
              }/>
              {/* Not Match Route */}
              <Route path="*" element={
              <ProtectedRoute nivelAuth={1} departmentsAuth={["*"]}>
                <Home/>
              </ProtectedRoute>} />
            </Route>
            <Route path="*" element={<Login/>}/>
          </Routes>
        </OptaProvider>
      </AuthProvider>
    </ThemeProvider>



  );
}

export default App;