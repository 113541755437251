import React from 'react'
import {
    Stack,
    Typography,
    Box,
    Grid
} from '@mui/material'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';


function JugadoresFichas() {


  const handleClickOnCard = (clave) =>{
    handleDownloadPDF(clave);
  }

  const handleDownloadPDF = (clave)=>{
    // using Java Script method to get PDF file
    fetch(`https://necaxa-analisis.s3.us-west-1.amazonaws.com/analisis/scouting/jugadores/fichas/${clave}.pdf`).then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = `${clave}.pdf`;
            alink.click();
        })
    })
}

  return (
    <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
        marginY:{xs:1, sm:1, md:1,lg:10,xl:10},
        overflow:"hidden",
        maxWidth:"100%"}}>
      <Box width={300} sx={{backGround:"black" ,display:{xs:"none",sm:"none", md:"block"},}}></Box>
      
      <Box 
        sx={{
            width:"100%",
            marginX:{md:0,xl:8,lg:8},
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center"

        }}
      >
    <Typography variant="h3" sx={{textAlign:"center", }}>Fichas de Jugadores</Typography>
    <Grid container spacing={2} sx={{marginTop:5, marginX:"auto",paddingBottom:4,}}  minHeight={"100%"}>
    {
        presentaciones.map((presentacion)=>{
            return (
              <Grid key={presentacion.clave} item xs={12} md={4}>
                <Card sx={{ minWidth:300, maxWidth: 345, height:{sm:400,lg:345}}}  elevation={5}>
                  <CardActionArea  onClick={()=>handleClickOnCard(presentacion.clave)}>
                    <CardMedia
                      component="img"
                      height="100"
                      image={`https://necaxa-analisis.s3.us-west-1.amazonaws.com/analisis/scouting/jugadores/fichas/${presentacion.clave}.png`}
                      alt={presentacion.clave}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {presentacion.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {presentacion.fecha}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary" onClick={()=>handleClickOnCard(presentacion.clave)}>
                      Descargar Presentación
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              );
        })
    }
    </Grid>
    </Box>
    </Stack>
  )
}

export default JugadoresFichas;


const presentaciones = [
      {
        name: "Agustín Oliveros",
        fecha:"Marzo 2023",
        clave:"AOM23"
    },

    {
      name: "Agustín Oliveros (Inglés)",
      fecha:"Marzo 2023",
      clave:"AOM23I"
  },
  {
    name: "Jorge Rodríguez",
    fecha:"Mayo 2023",
    clave:"JRT"
},
{
  name: "Emilio Martinez",
  fecha:"Mayo 2023",
  clave:"EMP"
},

    {
      name: "Maximiliano Silvera",
      fecha:"Diciembre 2022",
      clave:"MS"
  },


    {
        name: "Vicente Poggi",
        fecha:"Junio 2021",
        clave:"VP"
    },
    {
        name: "Vicente Poggi (Inglés)",
        fecha:"Junio 2021",
        clave:"VP-I"
    },
    {
        name: "Pablo Domínguez",
        fecha:"Octubre 2022",
        clave:"JPD"
    },
    {
      name: "Damián Batallini",
      fecha: "Noviembre 2022",
      clave: "DB"
    },
    {
        name: "Edson Partida",
        fecha:"Octubre 2022",
        clave:"EP"
    },
    {
        name: "Agustín Oliveros (Inglés)",
        fecha:"Mayo 2021",
        clave:"AO-I"
    },
    {
        name: "Yerson Chacón",
        fecha:"Octubre 2022",
        clave:"YC"
    },
    {
      name: "Agustín Obando",
      fecha:"Abril 2021",
      clave:"AOB"
  },
    {
      name: "Angelo Araos",
      fecha:"Noviembre 2021",
      clave:"AA"
  },
  {
    name: "Francisco Figueroa",
    fecha:"Noviembre 2021",
    clave:"FF"
},
{
  name: "Miltón Giménez",
  fecha:"Noviembre 2021",
  clave:"MG"
},
{
  name: "Luca Martínez",
  fecha:"Noviembre 2021",
  clave:"LM"
},
{
  name: "Luis Á Mendoza",
  fecha:"Noviembre 2021",
  clave:"LAM"
},
{
  name: "Ángel Tecpanécatl",
  fecha:"Noviembre 2021",
  clave:"AT"
},
{
  name: "Dieter Villalpando",
  fecha:"Noviembre 2021",
  clave:"DV"
},
{
  name: "Edison Flores",
  fecha:"Noviembre 2021",
  clave:"EF"
},
{
  name: "Jorge L Valdivia",
  fecha:"Noviembre 2021",
  clave:"JLV"
},
{
  name: "Iván Angulo",
  fecha:"Mayo 2022",
  clave:"IA"
},
{
  name: "Pablo Barrera",
  fecha:"Mayo 2022",
  clave:"PB"
},
{
  name: "Jesús Godínez",
  fecha:"Mayo 2022",
  clave:"JG"
},
{
  name: "Arturo Palma",
  fecha:"Junio 2021",
  clave:"AP"
},
{
  name: "A. Mora vs J. Segovia",
  fecha:"Mayo 2022",
  clave:"AMvJS"
},
    

]